<header id="header" class="main-header header-size-custom border-bottom-0" data-sticky-shrink="false">
    <div class="container">
        <nav class="navbar navbar-expand-lg center-nav">
            <!-- Logo
             ============================================= -->
            <div id="logo">
                <a href="/" class="standard-logo mainLogo"><img src="../../../assets/images/home/cashiback-logo.png"
                        alt="Cashi Back Logo" /></a>
                <a href="/" class="retina-logo mainLogo"><img src="../../../assets/images/home/cashiback-logo@2x.png"
                        alt="Cashi Back Logo" /></a>
            </div>
            <!-- #logo end -->
  
            <button class="navbar-toggler align-self-end collapsed" type="button" data-toggle="collapse"
                data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                aria-label="Toggle navigation">
                <span></span>
                <span></span>
                <span></span>
            </button>
  
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <!-- <li class="nav-item">
                        <span class="menu-bg col-auto align-self-start d-flex"></span>
                    </li> -->
                    <li class="nav-item nav-item-search no-border-buttom">
                          <form class="form-inline" (ngSubmit)="searchResult()">
                              <div class="input-group search-box">
                                  <input type="text" class="search-input"
                                      placeholder="{{'search'| translate }}" [(ngModel)]="searchQuery" name="search" #search="ngModel" autocomplete="off"/>
  
                                  <button type="submit" class="btn btn-search ms-auto">
                                      <i class="icon-line-search"></i>
                                  </button>
                              </div>
                          </form>
                    </li>
                    <li class="nav-item">
                        <a  class="nav-link" *ngIf="localeId === 'en'" (click)="changeLang('ar')">
                                <i class="icon-language"></i> <small>عربى</small>
                        </a>
  
                        <a  class="nav-link" *ngIf="localeId === 'ar'" (click)="changeLang('en')">
                                <i class="icon-language"></i> <small>English</small>
                        </a>
                    </li>
  
  
                    <li class="nav-item" *ngIf="!loggedInUser?.user">
                        <a class="nav-link" data-toggle="modal" data-target="#sign-in">
                            <div class="header-buttons">
                                <small >{{'sign-in' | translate}}</small>
                            </div>
                        </a>
  
                    </li>
  
  
  
  
                    <li class="nav-item" *ngIf="!loggedInUser?.user">
                            <a class="nav-link button button-rounded button-border button-white button-light join-btn" data-toggle="modal" data-target="#newRegisterModal">
                                <small>{{'join-now' | translate }} </small>
                            </a>
                    </li>
  
  
                        <li class="nav-item" *ngIf="loggedInUser?.user">
                            <a class="nav-link" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="d-flex align-items-center">
                                    <div class="user-img-header">
                                        <!-- <img src="../../../../assets/images/profile/abeer.jpg"> -->
                                        Hi
                                    </div>
                                    <small>{{loggedInUser.user.first_name || loggedInUser.user.username}}</small>
                                    <i class="icon-angle-down"></i>
                                </div>
                            </a>
                            <div class="dropdown-menu no-background-drop-menu user-menu border-0 shadow" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item no-background-drop-menu" href="#" routerLink="profile/cashback-overview" >
                                    <div class="country-flag-div">
                                      <i class="fal fa-poll-h"></i>
                                        <small class="flag-text" >{{'cashback-overview' | translate}}</small>
                                    </div>
                                </a>
                                <a class="dropdown-item no-background-drop-menu" href="#" routerLink="profile/account-info">
                                    <div class="country-flag-div">
                                      <i class="fal fa-user-circle"></i>
                                        <small class="flag-text" >{{'account-information' | translate}}</small>
                                    </div>
                                </a>
                                <a class="dropdown-item no-background-drop-menu" href="#" routerLink="profile/fav-store">
                                    <div class="country-flag-div">
                                      <i class="fal fa-star"></i>
                                        <small class="flag-text" >{{'favorite-store' | translate}}</small>
                                    </div>
                                </a>
                                <a class="dropdown-item no-background-drop-menu" href="#" routerLink="profile/change-password">
                                    <div class="country-flag-div">
                                      <i class="fal fa-lock-alt"></i>
                                        <small class="flag-text" >{{'update-password' | translate}}</small>
                                    </div>
                                </a>
                                <a class="dropdown-item no-background-drop-menu" href="#" routerLink="profile/withdrawal">
                                    <div class="country-flag-div">
                                      <i class="fal fa-envelope-open-dollar"></i>
                                        <small class="flag-text" >{{'withdraw-money' | translate}}</small>
                                    </div>
                                </a>
                                <a class="dropdown-item no-background-drop-menu" href="#" routerLink="referrals" *ngIf="loggedInUser?.user" >
                                    <div class="country-flag-div">
                                      <i class="fal fa-user-plus"></i>
                                        <small class="flag-text" >{{'refer-and-earn' | translate}}</small>
                                    </div>
                                </a>
  
                                <a class="dropdown-item no-background-drop-menu" (click)="openLoginDialog()"  *ngIf="!loggedInUser?.user" >
                                    <div class="country-flag-div">
                                      <i class="fal fa-user-plus"></i>
                                        <small class="flag-text" i18n>{{'refer-and-earn' | translate}}</small>
                                    </div>
                                </a>
                                <a class="dropdown-item no-background-drop-menu" href="#" routerLink="help">
                                    <div class="country-flag-div">
                                      <i class="fal fa-question-circle"></i>
                                        <small class="flag-text" i18n>{{'help' | translate}}</small>
                                    </div>
                                </a>
                                <a class="dropdown-item no-background-drop-menu" href="#" (click)="logout()">
                                    <div class="country-flag-div">
                                      <i class="fal fa-sign-out"></i>
                                        <small class="flag-text" i18n>{{'sign-out' | translate}}</small>
                                    </div>
                                </a>
                            </div>
                        </li>
  
                        <li class="nav-item" *ngIf="loggedInUser?.user">
                            <a class="nav-link" href="#">
                                   <small>{{loggedInUser.user.customer?.available_balance || '0,00'}} SAR</small>
  
                            </a>
                        </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" routerLink="/help" routerLinkActive="true">
                            <i class="icon-line-help-circle help-icon d-none d-lg-block"></i>
                            <span class="help-title d-block d-lg-none">Help</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
  </header>
  <app-menu></app-menu>
  
      <section class="unverified" [hidden]="!(loggedInUser?.user && !loggedInUser?.user?.customer?.is_verified)">
          <div class="row">
              <div class="container">
                  <p i18n> <img src="../../../../assets/images/unverified/speech-bubble.png" > account is unverified, please <a  (click)="openVerificationDialog()" data-lightbox="inline">click here </a>to verify your account or you wont be able to get your cashback</p>
              </div>
          </div>
        </section>