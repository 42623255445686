<section class="Top-Selling-category section-gap">
  <div class="container">
    <app-top-selling class="row mb-5"></app-top-selling>
    <ngx-slick-carousel class="main-slider" #slickModal="slick-carousel" [config]="slideConfig"
      (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
      (beforeChange)="beforeChange($event)">
      <div ngxSlickItem class="slider-item" *ngFor="let category of categories">
        <div [ngClass]="category.css_class" class="selling-store p-3">
          <a class="nav-link slider-link" (click)="retrieveStore(category)"> <img
              [src]="cdn + category.image"></a>
          <p class="store-category"> {{category | translateName}} </p>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</section>
