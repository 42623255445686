import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/core/service/home.service';
import { Sliders } from 'src/app/core/models/sliders.model';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { tap } from 'rxjs/operators';
import { LoginResponse } from 'src/app/core/models/login-response.model';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-sliders',
  templateUrl: './sliders.component.html',
  styleUrls: ['./sliders.component.css']
})
export class SlidersComponent implements OnInit {

  slideConfig = {
    centerMode: true,
    centerPadding: '10%',
    slidesToShow: 1,
    autoplay:true,
    autoplaySpeed:8000,
    arrows:false,
    dots: false,
    responsive: [
              {
                breakpoint: 576,
                settings: {
                  centerPadding: '0'
                }
              }
            ]
  };

  sliders : Sliders [] = [];
  cdn  = environment.cdn;
  currentVideo: string;
  url: string;
  loggedInUser : LoginResponse;

  constructor(private homeService: HomeService, private sanitizer: DomSanitizer, private authService: AuthService) { 
    this.authService.currentUser.subscribe(x => this.loggedInUser=x);

  }

  ngOnInit() {
    this._retrieveSliders();
  }

  private _retrieveSliders() {
    this.homeService.retrieveSliders().subscribe({
      next: data => this.sliders=data
    })
  }

  safeURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
