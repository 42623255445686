import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiClientService } from '../http-client/api-client.service';
import { SocialNetwork } from '../models/social-network.model';

@Injectable({
  providedIn: 'root'
})
export class SocialService {

  constructor(private apiClient: ApiClientService) { }

  list():Observable<SocialNetwork[]> {
   return this.apiClient.getRequest<SocialNetwork[]>(`social-networks`).pipe(shareReplay(300));
  }
}
