import { Component, OnInit, Input } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';
import { StoreService } from 'src/app/core/service/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-store-card',
  templateUrl: './top-store-card.component.html',
  styleUrls: ['./top-store-card.component.css']
})
export class TopStoreCardComponent implements OnInit {
  @Input() store: Store;
  @Input() isFav = false;
  cdn = environment.cdn;

  constructor(private storeService: StoreService) { }

  ngOnInit(): void {
  }


  addOrRemoveFav() : void {
    if (this.isFav) {
      this.storeService.removeFavStore(this.store.pk).subscribe(data => this.isFav = false);
    } else {
      this.storeService.addFavStore(this.store.pk).subscribe(data => this.isFav = true);      
    }    
  }
}
