import { Component, OnInit, Input } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-populare-store-card',
  templateUrl: './populare-store-card.component.html',
  styleUrls: ['./populare-store-card.component.css']
})
export class PopulareStoreCardComponent implements OnInit {

  @Input() store: Store;
  @Input() isFav = false;
  cdn = environment.cdn;

  constructor() { }

  ngOnInit(): void {
  }

}
