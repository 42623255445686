import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompleteResetPasswordComponent } from './complete-reset-password/complete-reset-password.component';
import { CompleteResetPasswordNotificationComponent } from './complete-reset-password-notification/complete-reset-password-notification.component';

const routes: Routes = [
  {
    path: 'password/reset/confirm', children :[
      {path: ':uid/:token', component: CompleteResetPasswordComponent},
      {path: 'complete', component: CompleteResetPasswordNotificationComponent}    
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
