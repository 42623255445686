import { Injectable } from '@angular/core';
import { ApiClientService } from '../http-client/api-client.service';
import { Observable } from 'rxjs';
import { Paginator } from '../models/pagination.model';
import { CustomerCampaign } from '../models/customer-campaign.model';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private _apiClient: ApiClientService) { }

  retrieveCampagin(): Observable<Paginator<CustomerCampaign>> {
    return this._apiClient.getRequest(`campaigns`);
  }
}
