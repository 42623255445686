
        <h1 class="main-header-title" i18n> <i class="fal fa-lock-alt"></i> Update Password</h1>
        <app-notification *ngIf="err" [error]="err"></app-notification>
        <div class="profile-password mt-2">

                <form #changePasswordForm="ngForm" (ngSubmit)="changePasswordForm.form.valid && doChangePassword() ">
                    <div class="row">
                    <div class="col-lg-12 col-md-12 col-xs-12">
                        <div class="form-group">
                            <label i18n>Current password</label>
                            <input type="password" class="form-control" i18n-placeholder placeholder="" [(ngModel)]="changePassword.old_password" name="old_password" required #old_password="ngModel" [ngClass]="(changePasswordForm.submitted && old_password.invalid) ? ' invalid' : ''"/>

                                <p *ngIf="changePasswordForm.submitted && old_password.invalid && old_password.errors.required" class="invalid" i18n>
                                    old password is required
                                  </p>

                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12">
                        <div class="form-group">
                            <label i18n>New password</label>
                            <input type="password" class="form-control" i18n-placeholder [(ngModel)]="changePassword.new_password1" name="new_password1" required #new_password1="ngModel" [ngClass]="(changePasswordForm.submitted && new_password1.invalid) ? ' invalid' : ''"/>

                                <p *ngIf="changePasswordForm.submitted && new_password1.invalid && new_password1.errors.required" class="invalid" i18n>
                                    New password is required
                                  </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12">
                        <div class="form-group">
                            <label i18n>Confirm password</label>
                            <input type="password" class="form-control" i18n-placeholder [(ngModel)]="changePassword.new_password2" name="new_password2" #new_password2="ngModel" required [ngClass]="(changePasswordForm.submitted && new_password2.invalid) ? ' invalid' : ''"/>

                                <p *ngIf="changePasswordForm.submitted && new_password2.invalid && new_password2.errors.required" class="invalid" i18n>
                                    confirm password is required
                                  </p>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-xs-12">
                        <button class="main-btn" type="submit" i18n>Update Password</button>
                    </div>
                    </div>
                </form>


        </div>
