import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomerCampaign } from 'src/app/core/models/customer-campaign.model';
import { UserPromotion } from 'src/app/core/models/user-promotion.model';
import { Store } from 'src/app/core/models/store.model';

@Component({
  selector: 'app-promotion-card',
  templateUrl: './promotion-card.component.html',
  styleUrls: ['./promotion-card.component.css']
})
export class PromotionCardComponent implements OnInit {

  @Input() store: Store;
  cdn = environment.cdn;
  constructor() {}

  ngOnInit() {    
  }

}
