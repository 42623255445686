import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { SlidersComponent } from './sliders/sliders.component';
import { CampaignComponent } from './campaign/campaign.component';
import { DiscountsComponent } from './discounts/discounts.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { JoinNowComponent } from './join-now/join-now.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TopSellingComponent } from './top-selling/top-selling.component';
import { PopularContentComponent } from './popular-content/popular-content.component';
import { PopularSliderComponent } from './popular-content/popular-slider/popular-slider.component';
import { ContentComponent } from './popular-content/content/content.component';

@NgModule({
  declarations: [HomeComponent, SlidersComponent, CampaignComponent, DiscountsComponent, JoinNowComponent, TopSellingComponent, PopularContentComponent, PopularSliderComponent, ContentComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModuleModule,
    SlickCarouselModule
  ]
})
export class HomeModule { }
