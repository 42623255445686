import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoresRoutingModule } from './stores-routing.module';
import { TopStoresComponent } from './top-stores/top-stores.component';
import { FavStoresComponent } from './fav-stores/fav-stores.component';
import { StoresByCategoriesComponent } from './stores-by-categories/stores-by-categories.component';
import { StoreDetailsComponent } from './store-details/store-details.component';
import { StoreDetailHeaderComponent } from './store-details/store-detail-header/store-detail-header.component';
import { AboutStoreComponent } from './store-details/about-store/about-store.component';
import { StoreCategoriesComponent } from './store-details/store-categories/store-categories.component';
import { CashbackCycleComponent } from './store-details/cashback-cycle/cashback-cycle.component';
import { StoreTermsComponent } from './store-details/store-terms/store-terms.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { StoreRedirectComponent } from './store-redirect/store-redirect.component';
import { AllStoresComponent } from './all-stores/all-stores.component';
import { AllStoresCategoryComponent } from './all-stores/all-stores-category/all-stores-category.component';
import { TopPopularStoresComponent } from './all-stores/top-popular-stores/top-popular-stores.component';
import { AllStoreDetailsComponent } from './all-stores/all-store-details/all-store-details.component';
import { OffersComponent } from './store-details/offers/offers.component';
import { TrendingStoresComponent } from './store-details/trending-stores/trending-stores.component';

@NgModule({
  declarations: [
    TopStoresComponent, 
    FavStoresComponent, 
    StoresByCategoriesComponent, 
    StoreDetailsComponent, 
    StoreDetailHeaderComponent, 
    AboutStoreComponent, 
    StoreCategoriesComponent, 
    CashbackCycleComponent, 
    StoreTermsComponent, 
    StoreRedirectComponent, 
    AllStoresComponent, 
    AllStoresCategoryComponent, 
    TopPopularStoresComponent, 
    OffersComponent, 
    TrendingStoresComponent, 
    AllStoreDetailsComponent
  ],
  imports: [
    CommonModule,
    StoresRoutingModule,
    SharedModuleModule,
    InfiniteScrollModule
  ]
})
export class StoresModule { }
