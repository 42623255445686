import { Component, OnInit } from "@angular/core";
import { StoreService } from "src/app/core/service/store.service";
import { Store } from "src/app/core/models/store.model";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/core/service/auth.service";
import { LoginResponse } from "src/app/core/models/login-response.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-store-redirect",
  templateUrl: "./store-redirect.component.html",
  styleUrls: ["./store-redirect.component.css"],
})
export class StoreRedirectComponent implements OnInit {
  store: Store;
  loggedInUser: LoginResponse;
  constructor(
    private storeService: StoreService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const storeId = Number(this.route.snapshot.paramMap.get("id"));
    this.storeService
      .retreiveStoreWithRedirectLink(storeId)
      .subscribe((data) => {
        this.store = data;
        setTimeout(() => {
          window.location.href = this.store.url;
        }, 5000);
      });
    this.loggedInUser = this.authService.curretUser;
    console.log(this.store.url);
  }

  get logo() {
    return environment.cdn + this.store.logo;
  }
}
