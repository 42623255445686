import { Component, OnInit } from '@angular/core';
import { Country } from 'src/app/core/models/countries.model';
import { LoginResponse, User } from 'src/app/core/models/login-response.model';
import { AuthService } from 'src/app/core/service/auth.service';
import { CountriesService } from 'src/app/core/service/countries.service';
import { ProfileService } from 'src/app/core/service/profile.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.css']
})
export class AccountInfoComponent implements OnInit {
  user: User ;
  token: string;
  updatedUser: LoginResponse;
  countries : Country [] = [];
  country_code : number;
  successMessage: string;
  errorMessage: string;
  constructor(private authSerivce: AuthService, private countryService: CountriesService, private profileService: ProfileService) { 
    this._retrieveCountries();
  }

  ngOnInit(): void {
    // this.retrieveCustomer();
    
  }

  retrieveCustomer(): void {   
    console.log('customer');
         
    this.authSerivce.currentUser.subscribe(x => {
          this.user =  Object.assign({}, x.user);
          this.updatedUser = Object.assign({}, x);
          this.user.customer.mobile_number = this.user.customer.mobile_number;
          this.country_code = this.user.customer.country;
        });
  }

  changeInfo() : void {
    this.profileService.updateProfile(this.user).subscribe({
      next: data => {
        this.user = data;        
        this.updatedUser.user = data;
        this.authSerivce.addLoggedInCustomerToStorage(this.updatedUser);
        this.successMessage = 'Information updates successfuly';
      }

    })
  }

  _retrieveCountries() : void {
    console.log('counrty');
    
    this.countryService.retrieveCountries().subscribe({
      next: data =>  this.countries = data,
      error: data => data,
      complete: () => this.retrieveCustomer() 
    });
  }

  selectGender(gender: string) {
    this.user.customer.gender = gender;
  }
}
