import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateName',
  pure: false
})
export class TranslateNamePipe implements PipeTransform {

  constructor(private translate: TranslateService) {

  }

  transform(value: any): string {
    if (this.translate.currentLang == 'en') {
      return value['name_en'] || value['content_en'];
    }
    return value['name_ar'] || value['content_ar'];
  }

}
