<h1 class="main-header-title">
  <i class="fal fa-user-circle"></i>
  Account Information
</h1>


<div class="rest-message" *ngIf="successMessage">
  <i class="fas fa-check-circle" aria-hidden="true"></i> {{successMessage}}
</div>

<div class="error-message" *ngIf="errorMessage">
  <i class="fas fa-check-circle" aria-hidden="true"></i> {{errorMessage}}
</div>

<section class="profile-information mt-5 " *ngIf="user">

  <form #accountInfoForm="ngForm" (ngSubmit)="accountInfoForm.form.valid && changeInfo()">
    <div class="row">

      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <label i18n>First Name</label>
          <input type="text" class="form-control" [(ngModel)]="user.first_name" name="firstName" #firstName="ngModel">
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <label i18n>Last Name</label>
          <input type="text" class="form-control" [(ngModel)]="user.last_name" name="lastName" #lastName="ngModel">
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <label i18n>Email</label>
          <input type="email" class="form-control" [(ngModel)]="user.email" name="email" #email="ngModel" readonly>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <label>Mobile Number</label>
          <div class="input-group button-with-select">
            <div class="input-group-prepend">
              <select [(ngModel)]="country_code" name="countryCode" #countryCode="ngModel">
                <option *ngFor="let country of countries" [value]="country.pk">{{country.phone}}</option>
              </select>
            </div>
            <input type="tel" class="form-control" name="phoneNumber" pattern="[1-9]{1}[0-9]{9}"
              [(ngModel)]="user.customer.mobile_number" #phoneNumber="ngModel">

          </div>
          <p *ngIf="accountInfoForm.submitted && phoneNumber.invalid && phoneNumber.errors.required" class="invalid"
            i18n>
            Mobile number is required
          </p>

          <p *ngIf="accountInfoForm.submitted && phoneNumber.invalid && phoneNumber.errors.pattern" class="invalid"
            i18n>
            Mobile number invalid
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <label i18n>Date Of Birth</label>
          <input type="date" class="form-control" name="birthDate" [(ngModel)]="user.customer.date_of_birth"
            #birthDate="ngModel">
        </div>
      </div>




      <div class="col-lg-6 col-md-6 col-xs-12 gender-toggle">
        <div class="form-group">
          <label>Gender <span class="gender-optional">(Optional)</span></label>
          <mat-radio-group [(ngModel)]="user.customer.gender" name="gender" #gender="ngModel">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn " [ngClass]="user.customer.gender == 'Male' ? 'active' : ''">
                <input type="radio" name="gender" id="Male" checked (click)="selectGender('Male')"> Male
                <!-- <mat-radio-button class="example-margin" value="Male">Male</mat-radio-button> -->

              </label>
              <label class="btn" [ngClass]="user.customer.gender == 'Female' ? 'active' : ''">
                <input type="radio" name="gender" id="Female" checked  (click)="selectGender('Female')"> Female
                <!-- <mat-radio-button  class="example-margin" value="Female">Female</mat-radio-button> -->

              </label>
            </div>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-xs-12 update-information-btn">
        <button data-lightbox="inline" class="main-btn" type="submit" i18n>Update Information</button>
      </div>




    </div>
  </form>


</section>
