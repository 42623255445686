import { Component, OnInit, Input } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';

@Component({
  selector: 'app-store-categories',
  templateUrl: './store-categories.component.html',
  styleUrls: ['./store-categories.component.css']
})
export class StoreCategoriesComponent implements OnInit {

  @Input() store: Store;
  constructor() { }

  ngOnInit() {
  }

  get endSlicing(){
    return (this.store.stores_categories.length / 2) + 1;
  }

  get startSlicing() {
    return (this.store.stores_categories.length / 2) + 1;
  }

}
