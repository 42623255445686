<div class="store-cat section-gap">
  <div class="container">
    <h1 class="main-title" i18n> Stores</h1>
    <div class="products">
      <div class="row mt-2 row-cols-lg-5 row-cols-md-3 row-cols-sm-2" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()">
        <app-store-card *ngFor="let store of stores" [store]="store" class="col mb-3" [isFav]="store.is_fav"></app-store-card>
      </div>
    </div>
  </div>
</div>
<app-mobile-app-component></app-mobile-app-component>
