import { Injectable } from '@angular/core';
import { ApiClientService } from '../http-client/api-client.service';
import { Faq } from '../models/faq.model';
import { Observable } from 'rxjs';
import { TermsAndCondition } from '../models/terms-and-condition.model';
import { VerificationOTP } from '../models/opt.model';
import { Rules } from '../models/rules.model';
import { ContactUsModel } from '../models/contact-us.model';
import { ContactUsInfo } from '../models/contact-info.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private apiClient: ApiClientService) { }

  retrieveFaq () : Observable<Faq[]> {
    return this.apiClient.getRequest<Faq[]>(`faq`);
  }

  retrieveTAC() : Observable<TermsAndCondition> {
    return this.apiClient.getRequest<TermsAndCondition>(`terms-and-conditions`);
  }

  sendOTP(): Observable<any> {
    return this.apiClient.postRequest<any>(`opt/send`, null);
  }

  verifyOTP(otp: VerificationOTP): Observable<any> {
    return this.apiClient.postRequest<any>(`opt/verify`, otp);
  }

  retrieveRules(): Observable<Rules[]> {
    return this.apiClient.getRequest(`rules`);
  }

  sendComplaint(complaint: ContactUsModel) : Observable<any> {
    return this.apiClient.postRequest(`complaints`, complaint);
  }

  contactInfo(): Observable<ContactUsInfo> {
    return this.apiClient.getRequest(`contact-info`)
  }
 
}
