import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Categories } from 'src/app/core/models/categories.model';
import { CategoriesService } from 'src/app/core/service/categories.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-popular-slider',
  templateUrl: './popular-slider.component.html',
  styleUrls: ['./popular-slider.component.css']
})
export class PopularSliderComponent implements OnInit {
  categories: Categories[] = [];
  cdn = environment.cdn;
  catClass = 'popular';
  randomClasses = ['popular', 'dining', 'electronic', 'fashion', 'delivery', 'groceries', 'health']
  @Output() categoryEmitter: EventEmitter<Categories> = new EventEmitter();

  slideConfig = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    arrows:true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



 constructor(private _categoriesService: CategoriesService) { }

  ngOnInit(): void {
    this._retrieveCategories();
  }

  private _retrieveCategories(): void {
    this._categoriesService.retrievePopularCategories().subscribe(data => {
      this.categories= data.filter(cat => cat.show_in_container === true);
      this.categories = this._attachCssClass(this.categories);
      this.retrieveStore(this.categories[0]);
    });
  }

  retrieveStore(category: Categories): void{
        this.categoryEmitter.emit(category);
  }

  private _attachCssClass(categories: Categories[]) : Categories[]{
    console.log(this.categories);
    
    return this.categories.map(cat => {
      cat.css_class=this._randomClass(); 
      return cat;
    });
  }

  private _randomClass():string {
    return this.randomClasses[Math.floor(Math.random() * this.randomClasses.length)];
  }

  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {    
    this.retrieveStore(this.categories[e.currentSlide]);
  }
  
  beforeChange(e) {
    this.retrieveStore(this.categories[e.currentSlide]);
  }
}
