
 <div class="rest-message" *ngIf="successMessage">
    <i class="fas fa-check-circle" aria-hidden="true"></i> {{successMessage}}
  </div>

  <div class="error-message" *ngIf="errorMessage">
    <i class="fas fa-check-circle" aria-hidden="true"></i> {{errorMessage}}
  </div>




    <form #withdrawalBank="ngForm" (ngSubmit)="withdrawalBank.form.valid && do()">

                            <div class="row">
                                <div class="col-12">
                                    <h2 class="second-header-title">Transfer to Bank</h2>
                                    <p class="transfer-description">Takes 3-5 working days to reflect in your bank
                                        account</p>
                                </div>
                            </div>
                            <div class="row cards-wrapper">
                                 <app-bank-card (selectedBank)="withdrawal.bankId=$event"  [bank]="bank" *ngFor="let bank of banks" class="col-12 col-md-6 mb-5"></app-bank-card>

                                 <div class="col-12 col-md-6">
                                    <div class="add-bank-btn">
                                        <a href="#" data-toggle="modal" data-target="#addBankForm">
                                            <i class="fal fa-plus mr-1"></i>
                                            <span>{{'add-bank' | translate}}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="withdrawal-amount">
                                        <p> Withdrawal Amount (Minimum required: {{min?.value || '0' | currency}})</p>
                                        <input type="number"  class="withdrawal-amount-input w-50" [value]="loggedInUser.user.customer.available_balance" [(ngModel)]="withdrawal.amount" name="amount" #amount="ngModel" [min]="min?.value || 0" required>
                                        <p *ngIf="withdrawalBank.submitted && amount.invalid && amount.errors.required" class="invalid" i18n>
                                          Amount is required
                                      </p>
                                      <p *ngIf="withdrawalBank.submitted && amount.invalid && amount.errors.min" class="invalid" i18n>
                                          Minimum amount invalid
                                      </p>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="withdraw-btn-upper">
                                        <button  class="main-btn"  type="submit" [disabled]="(withdrawal.amount || 0)  < min?.value " >{{'withdraw' | translate }} </button>

                                    </div>
                                </div>
                            </div>
</form>

<app-add-local-bank (reload)="reload($event)"></app-add-local-bank>
