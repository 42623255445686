
<div class="col single-store">
  <div class="icon-wrapper">
        <i  aria-hidden="true" [ngClass]="store.is_fav ? 'fas fa-star' : 'far fa-star'" (click)="addOrRemoveFav()"></i>
</div>
        <a [routerLink]= "['/stores', store.pk, 'details']" class="store-details py-4 px-2">

          <div class="store-logo-wrapper">
                <img [src]="cdn + store.logo" alt="" srcset="">
        </div>
        <div class="store-cashDetails text-center">
            <h3 class="mb-3">{{store | translateName}}</h3>
            <p i18n>Up to {{store.top_rate_v}} Cashback</p>
          </div>
            </a>

</div>
