<!-- === === == strat second menu == == ==  -->
<div class="bottom-nav d-none d-lg-block">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light p-0 justify-content-end">
      <button class="navbar-toggler align-self-end collapsed border-0" type="button" data-toggle="collapse"
        data-target="#bottom-nav" aria-controls="bottom-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div class="primary-menu with-arrows collapse navbar-collapse" id="bottom-nav">

        <ul class="menu-container navbar-nav p-3 p-lg-0">
          <li class="menu-item me-auto categories-menu" (click)="toggleClass()" [class.show-sub-menu]="classApplied">
            <a class="menu-link" href="#" class="menu-link">
              <div class="d-flex align-items-center" i18n>
                <i class="far fa-bars me-2"></i>
                <p class="mb-0 fw-bold">{{'all-categories' | translate }}</p>
                <!-- <i class="icon-angle-down ms-2"></i> -->
              </div>
            </a>
            <ul class="sub-menu-container ps-0">

              <li class="menu-item" *ngFor="let category of categories" [class.show-second-sub-menu]="secondSubMenuApplied">
                <a class="menu-link" href="#" routerLink="/stores/{{category.slug}}/categories"
                  *ngIf="category.show_in_menu">
                  <div>{{category | translateName}}
                    <i class="icon-angle-down" *ngIf="category.children && category.children.length > 0"></i>
                  </div>
                </a>
                <ul class="sub-menu-container shadow p-2 rounded bg-white" *ngIf="category.children && category.children.length > 0">
                  <li class="menu-item" *ngFor="let child of category.children ">
                    <a class="menu-link" href="#" routerLink="/stores/{{child.slug}}/categories">
                      <div>{{child | translateName}}</div>
                    </a>
                  </li>

                </ul>
                <button class="sub-menu-trigger icon-chevron-right"
                  *ngIf="category.children && category.children.length > 0" (click)="toggleClass2()"></button>
              </li>
            </ul>
          </li>
          <li class="menu-item me-lg-4">
            <a class="menu-link" href="#" routerLink="stores/top">
              <div i18n>
                <i class="fal fa-store"></i> {{'top-cashback-store' | translate}}
              </div>
            </a>
          </li>

          <li class="menu-item me-lg-4" *ngIf="loggedInUser?.user">
            <a class="menu-link" href="#" routerLink="stores/fav" routerLinkActive="true" >
              <div i18n>
                <i class="fal fa-star"></i> {{'my-fav-store' | translate}}
              </div>
            </a>
          </li>

          <li class="menu-item me-lg-4">
            <a class="menu-link" routerLink="/referrals" routerLinkActive="true" *ngIf="loggedInUser?.user">
              <div i18n>
                <i class="fal fa-user-plus"></i> {{'refer-and-earn' | translate}} {{cashbackRule?.value | currency}} 
              </div>
            </a>

            <a class="menu-link" (click)="openLoginDialog()" *ngIf="!loggedInUser?.user">
              <div i18n>
                <i class="fal fa-user-plus"></i> {{'refer-and-earn' | translate}} {{cashbackRule?.value | currency}} 
              </div>
            </a>
          </li>

          <li class="menu-item mega-menu sub-menu">
            <a class="menu-link" href="#">
              <div>
                <i class="fal fa-bullseye-arrow"></i> {{'how-to-earn-cashback.menu' | translate}}
                <!-- <i class="icon-angle-down"></i> -->
              </div>
            </a>
            <div class="mega-menu-content mega-menu-style-2 how-to-earn" style="width: 1033.95px;">
              <div class="container">
                <div class="row">
                  <ul class="sub-menu-container mega-menu-column col-lg-12" >
                    <section class="steps-section" id="steps" >
                      <div class="container mt-3">
                        <div class="row">
                          <div class="col-9 d-flex align-items-center">
                            <div class="row">
                              <div class="col-lg-3 col-md-4 d-flex">
                                <div class="icon text-center me-3">
                                  <i class="icon-shop mt-1"></i>
                                </div>
                                <p class="text-left"> {{'how-to-earn-cashback.guide-1' | translate}}
                                </p>
                              </div>
                              <div class="col-sm-1 text-center separte-arrow">
                                <i class="far fa-arrow-right" aria-hidden="true"></i>
                              </div>
                              <div class="col-lg-3 col-md-4 d-flex">
                                <div class="icon text-center me-3">
                                  <i class="icon-money"></i>
                                </div>
                                <p class="text-left"> {{'how-to-earn-cashback.guide-2' |  translate}}</p>
                              </div>
                              <div class="col-sm-1 text-center separte-arrow">
                                <i class="far fa-arrow-right" aria-hidden="true"></i>
                              </div>
                              <div class="col-lg-3 col-md-4 d-flex">
                                <div class="icon text-center me-3">
                                  <i class="far fa-wallet" aria-hidden="true"></i>
                                </div>
                                <p class="text-left">{{'how-to-earn-cashback.guide-3' |  translate}}</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="row">
                              <div class="col-12">
                                <button routerLink="/how-to-earn" class="main-btn mb-0  " (click)="goToHowToEarnCB()">
                                  {{'how-to-earn-cashback.guide-4' |  translate}} <i class="far fa-arrow-right ms-1" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </ul>
                </div>
              </div>
            </div>
            <button class="sub-menu-trigger icon-chevron-right"></button>
          </li>
        </ul>
      </div><!-- #primary-menu end -->
    </nav>
  </div>
</div>
<!-- <div class="header-wrap-clone"></div> -->
