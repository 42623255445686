import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/service/common.service';
import { Faq } from 'src/app/core/models/faq.model';
import { FaqType } from 'src/app/core/constant/faq.type.enum';
import { ContactUsModel } from 'src/app/core/models/contact-us.model';
import { AuthService } from 'src/app/core/service/auth.service';
import { LoginResponse, User } from 'src/app/core/models/login-response.model';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  faq: Faq[] = [];
  faqType = FaqType;
  contactUs: ContactUsModel = {};
  loggedInUser : User;

  constructor(private commonService: CommonService, private authService: AuthService) { }

  ngOnInit() {
    this.retrieveFaq();
    this.loggedInUser = this.authService.curretUser.user;
  
  }

  retrieveFaq():void{
    this.commonService.retrieveFaq().subscribe(data => this.faq = data);
  }

  send(): void {
    this.commonService.sendComplaint(this.contactUs).subscribe({
      next: data => data
    })
  }


}
