import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';
import { StoreService } from 'src/app/core/service/store.service';

@Component({
  selector: 'app-trending-stores',
  templateUrl: './trending-stores.component.html',
  styleUrls: ['./trending-stores.component.css']
})
export class TrendingStoresComponent implements OnInit {
  stores:Store [] = [];
  constructor(private storeService: StoreService) { }

  ngOnInit(): void {
    this._retrieveStores();
  }


  private _retrieveStores(): void {
    this.storeService.retrireveTopStore().subscribe({
      next: data => this.stores = data.results
    })
  }
}
