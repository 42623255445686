
	<a [routerLink]= "['/stores', store.pk, 'details']">
	<div class="card">
		<img class="card-img-top" [src]="cdn + store.logo" alt="{{store | translateName}}">
		<div class="card-body">
				<div class="logo-wrapper shadow-sm">
					<img [src]="cdn + store.logo" alt="{{store | translateName}}">
				</div>
				<div class="card-content text-center">
					<h3>{{store | translateName}}</h3>
					<p i18n>Up to {{store.top_rate_v}}% Cashback</p>
					
				</div>
		</div>
	</div>
	</a>
