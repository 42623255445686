<h3>Top Travel Stores</h3>
<div class="row flex-container" id="travel-row">
    <div class="store_brand">
        <div class="image"> <img src="../../../../../assets/images/logos/samsung.png" alt="" srcset=""></div>
        <h3>Samsung</h3>
        <p>Up to 5% Cashback</p>
    </div>
    <div class="store_brand">
        <div class="image"> <img src="./../../../../assets/images/logos/airbnb.png" alt="" srcset=""></div>
        <h3>Airbnb</h3>
        <p>Up to 5% Cashback</p>
    </div>
    <div class="store_brand">
        <div class="image"> <img src="./../../../../assets/images/logos/samsung.png" alt="" srcset=""></div>
        <h3>Samsung</h3>
        <p>Up to 5% Cashback</p>
    </div>
    <div class="store_brand">
        <div class="image"> <img src="./../../../../assets/images/logos/airbnb.png" alt="" srcset=""></div>
        <h3>Airbnb</h3>
        <p>Up to 5% Cashback</p>
    </div>
    <div class="store_brand">
        <div class="image"> <img src="./../../../../assets/images/logos/samsung.png" alt="" srcset=""></div>
        <h3>Samsung</h3>
        <p>Up to 5% Cashback</p>
    </div>
    <div class="store_brand">
        <div class="image"> <img src="./../../../../assets/images/logos/airbnb.png" alt="" srcset=""></div>
        <h3>Airbnb</h3>
        <p>Up to 5% Cashback</p>
    </div>
    <div class="store_brand">
        <div class="image"> <img src="./../../../../assets/images/logos/samsung.png" alt="" srcset=""></div>
        <h3>Samsung</h3>
        <p>Up to 5% Cashback</p>
    </div>
    <div class="store_brand">
        <div class="image"> <img src="./../../../../assets/images/logos/airbnb.png" alt="" srcset=""></div>
        <h3>Airbnb</h3>
        <p>Up to 5% Cashback</p>
    </div>
  </div>