import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-complete-reset-password-notification',
  templateUrl: './complete-reset-password-notification.component.html',
  styleUrls: ['./complete-reset-password-notification.component.css']
})
export class CompleteResetPasswordNotificationComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  openLoginDialog() {
    
  }
}
