import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponentComponent } from './header-component/header-component.component';
import { FooterComponentComponent } from './footer-component/footer-component.component';
import { MobileAppComponentComponent } from './mobile-app-component/mobile-app-component.component';
import { StoreCardComponent } from './store-card/store-card.component';
import { MenuComponent } from './menu/menu.component';
import { PromotionCardComponent } from './promotion-card/promotion-card.component';
import { RouterModule } from '@angular/router';
import { AuthModule } from '../auth/auth.module';
import { FormsModule } from '@angular/forms';
import { TopStoreCardComponent } from './top-store-card/top-store-card.component';
import { PopulareStoreCardComponent } from './populare-store-card/populare-store-card.component';
import { StoreTrendingCardComponent } from './store-trending-card/store-trending-card.component';
import { StoreCardSecondComponent } from './store-card-second/store-card-second.component';
import { NotificationComponent } from './notification/notification.component';
import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import { TranslateNamePipe } from './pipe/translate-name.pipe';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    HeaderComponentComponent, 
    FooterComponentComponent, 
    MobileAppComponentComponent, 
    StoreCardComponent, 
    MenuComponent,
    PromotionCardComponent,
    TopStoreCardComponent,
    PopulareStoreCardComponent,
    StoreTrendingCardComponent,
    StoreCardSecondComponent,
    NotificationComponent,
    TranslateNamePipe],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TableModule,
    DialogModule,
    TranslateModule
    ],
  exports: [
    HeaderComponentComponent, 
    FooterComponentComponent, 
    MobileAppComponentComponent, 
    StoreCardComponent, 
    MenuComponent,
    PromotionCardComponent,
    PopulareStoreCardComponent,
    TopStoreCardComponent,
    StoreTrendingCardComponent,
    StoreCardSecondComponent,
    NotificationComponent,
    TranslateNamePipe,
    TranslateModule

  ]
})
export class SharedModuleModule { }
