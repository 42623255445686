import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { FormsModule } from '@angular/forms';
import { TermsComponent } from './terms/terms.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { CompleteResetPasswordComponent } from './complete-reset-password/complete-reset-password.component';
import { MobileVerificationComponent } from './mobile-verification/mobile-verification.component';
import { CountdownModule } from 'ngx-countdown';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModuleModule } from '../shared-module/shared-module.module';


@NgModule({
  declarations: [LoginComponent, RegistrationComponent, TermsComponent, ForgetPasswordComponent, CompleteResetPasswordComponent, MobileVerificationComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    CountdownModule,
    FormsModule, 
    SharedModuleModule
// 
  ],
  exports:[
    LoginComponent, 
    RegistrationComponent,
    ForgetPasswordComponent,
    MobileVerificationComponent
  ]
})
export class AuthModule { }
