<div >
    <div class="sidebar-widgets-wrap">

      <div class="all-categories">

        <div class="categories_title">
          <i class="icon-line-grid"></i> All Categories
        </div>
        <div class="categories_links">
          <ul>
            <li class="dropdown-submenu" *ngFor="let category of categories">
              <a  (click)="changeCategory(category)" *ngIf="category.show_in_menu">{{category.name_en}}</a>
              <div id="show_cat" style="display: none;"  *ngIf="category.children && category.children.length > 0">
                <ul>
                  <li><a href="#" routerLink="/stores/all/{{child.slug}}" *ngFor="let child of category.children ">{{child.name_en}}</a></li>
                  
                </ul>
              </div>
            </li>
           
           
          </ul>
        </div>

    </div>


    </div>
  </div>