import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Withdrawal } from 'src/app/core/models/withdrawal.model';
import { ProfileService } from 'src/app/core/service/profile.service';

@Component({
  selector: 'app-withdrawal-history',
  templateUrl: './withdrawal-history.component.html',
  styleUrls: ['./withdrawal-history.component.css']
})
export class WithdrawalHistoryComponent implements OnInit {

  withdrawal : Withdrawal [] = [];

  constructor(private profileService: ProfileService) { }
  ngOnInit(): void {
    this.loadWithdrwal();
  }

  private loadWithdrwal():void {
    this.profileService.retrtieveWithdrawal().subscribe(d => this.withdrawal = d)
  }
}
