import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';
import { LoginComponent } from '../../auth/login/login.component';

@Component({
  selector: 'app-un-authorized',
  templateUrl: './un-authorized.component.html',
  styleUrls: ['./un-authorized.component.css']
})
export class UnAuthorizedComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) { }


  ngOnInit(): void {
    if (this.authService.isUserLoggedIn()) {
       this.router.navigate(['/']);
    }
  }

 
  
}
