import { Component, OnInit, ViewChild } from '@angular/core';
import { ResetPassword } from 'src/app/core/models/reset-password.model';
import { AuthService } from 'src/app/core/service/auth.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  passwordReset: ResetPassword = {};
  successMessage : string;
  errorMessage: string;
  @ViewChild('resetPasswordForm') resetPasswordForm:NgForm;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  doResetPassword () : void {
    this.authService.resetPassword(this.passwordReset).subscribe({
      next: data => { this.successMessage = data.detail;this.resetPasswordForm.resetForm() ; },
      error: data => this.errorMessage = data.message[0]
    });
  }

  close(): void {
      // this.dialogForgetPasswordRef.close();
  }

  openRegistrationDialog() {
    // const dialogRef = this.dialog.open(RegistrationComponent, {panelClass: 'myapp-no-padding-dialog'});
    // this.close();  
  }

}
