import { Component, OnInit, Input } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';

@Component({
  selector: 'app-store-terms',
  templateUrl: './store-terms.component.html',
  styleUrls: ['./store-terms.component.css']
})
export class StoreTermsComponent implements OnInit {

  @Input() store:Store;
  constructor() { }

  ngOnInit() {
  }

}
