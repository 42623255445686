<div class="container clearfix">
    <div class="row gutter-40 col-mb-80">
        <!-- Post Content
        ============================================= -->
        <div class="col-lg-12">
                <div id="server-down">

                    
                    <section>
                        <img src="../../../../assets/images/system/under-maintenance.png">
                        <h2 >You are not authorized to access this page!</h2>
                        <a class="btn  btn-danger"data-toggle="modal" data-target="#sign-in" >Login </a>
                    </section>

                </div>
        </div><!-- .postcontent end -->

    </div>
</div>