import { Injectable } from '@angular/core';
import { ApiClientService } from '../http-client/api-client.service';
import { Observable } from 'rxjs';
import { Paginator } from '../models/pagination.model';
import { Clicks } from '../models/clicks.model';
import { Cashback } from '../models/cashback.model';
import { Withdrawal } from '../models/withdrawal.model';
import { Referral } from '../models/referral.model';
import { User } from '../models/login-response.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private apiClient: ApiClientService) { }

  retrieveClickActivity():Observable<Clicks []> {
    return this.apiClient.getRequest<Clicks[]>(`clicks`, null, true, false);
  }

  retrievePagination(link: string) : Observable<Paginator<any>>{
    return this.apiClient.getRequest<Paginator<any>>(`${link}`, null, true, true);
  }

  retrieveCashbackActivity():Observable<Cashback[]> {
    return this.apiClient.getRequest<Cashback[]>(`transaction`, null, true, false);
  }

  retrtieveWithdrawal():Observable<Withdrawal[]> {
    return this.apiClient.getRequest<Withdrawal[]>(`withdrawal-transaction`, null, true, false);
  }

  retrieveReferral(): Observable<Referral> {
    return this.apiClient.getRequest<Referral>(`referrals`, null, true, false);

  }

  processReferal(code: string) : Observable<void> {
    return this.apiClient.getRequest(`referrals/${code}`);
  }

  updateProfile(user:User):Observable<User> {
    return this.apiClient.postRequest<User>(`profile`, user, null, true);
  }
}
