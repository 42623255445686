import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EarnAndReferRoutingModule } from './earn-and-refer-routing.module';
import { EarnAndReferComponent } from './earn-and-refer.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { FormsModule } from '@angular/forms';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';



@NgModule({
  declarations: [EarnAndReferComponent],
  imports: [
    CommonModule,
    EarnAndReferRoutingModule,
    SharedModuleModule,
    FormsModule,
    ClipboardModule,
    // ShareModule ,
    ShareButtonsModule,
    ShareIconsModule 
  ]
})
export class EarnAndReferModule { }
