<div class="container clearfix">

    <div class="row gutter-40 col-mb-80">
        <!-- Post Content
        ============================================= -->
        <div class="col-lg-12">


                <div id="server-down">
                    
                    <section>
                        <img src="../../../../assets/images/system/server-down.png">
                        <h2 i18n>Server is down or can't be reached!</h2>
                        <p i18n>We are working to solve the problem, we will be back soon.</p>
                        <button class="btn  btn-danger" (click)="reload()" i18n>Try Again </button>
                    </section>

                </div>
              



        </div><!-- .postcontent end -->



    </div>
</div>