<div class="col-lg-9">

    <h3>Top {{category.name_en}} Stores</h3>
    <div class="row" id="pagination">
        <div class="col-lg-7"><h3>All {{category.name_en}} Stores</h3></div>
    </div>

<ul class="pagination pagination-circle pagination-primary flex-wrap">
    <li class="page-item disabled"><a class="page-link" href="#" aria-label="Previous"> <span aria-hidden="true">All</span></a></li>
    <li class="page-item active"><a class="page-link" href="#">A</a></li>
    <li class="page-item"><a class="page-link" href="#">B</a></li>
    <li class="page-item"><a class="page-link" href="#">C</a></li>
    <li class="page-item"><a class="page-link" href="#">D</a></li>
    <li class="page-item"><a class="page-link" href="#">E</a></li>
    <li class="page-item"><a class="page-link" href="#">F</a></li>
    <li class="page-item"><a class="page-link" href="#">G</a></li>
    <li class="page-item"><a class="page-link" href="#">H</a></li>
    <li class="page-item"><a class="page-link" href="#">i</a></li>
    <li class="page-item"><a class="page-link" href="#">J</a></li>
    <li class="page-item"><a class="page-link" href="#">K</a></li>
    <li class="page-item"><a class="page-link" href="#">l</a></li>
    <li class="page-item"><a class="page-link" href="#">M</a></li>
    <li class="page-item"><a class="page-link" href="#">N</a></li>
    <li class="page-item"><a class="page-link" href="#">o</a></li>
    <li class="page-item"><a class="page-link" href="#">p</a></li>
    <li class="page-item"><a class="page-link" href="#">q</a></li>
    <li class="page-item"><a class="page-link" href="#">r</a></li>
    <li class="page-item"><a class="page-link" href="#">s</a></li>
    <li class="page-item"><a class="page-link" href="#">t</a></li>
    <li class="page-item"><a class="page-link" href="#">u</a></li>
    <li class="page-item"><a class="page-link" href="#">v</a></li>
    <li class="page-item"><a class="page-link" href="#">w</a></li>
    <li class="page-item"><a class="page-link" href="#">x</a></li>
    <li class="page-item"><a class="page-link" href="#">y</a></li>
    <li class="page-item"><a class="page-link" href="#">z</a></li>
  </ul>

    <div class="row mt-2 travel_stores_section">
      <app-store-card-second class="travel_stores " [store]="store" *ngFor="let store of stores"></app-store-card-second>
    </div>
</div>