import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyComponent } from './privacy/privacy.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { StaticRoutingModule } from './static-routing.module';
import { AboutUsComponent } from './about-us/about-us.component';
import { TacComponent } from './tac/tac.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HelpComponent } from './help/help.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { ServerErrorPageComponent } from './server-error-page/server-error-page.component';
import { HowToEarnCashbackComponent } from './how-to-earn-cashback/how-to-earn-cashback.component';
import { UnAuthorizedComponent } from './un-authorized/un-authorized.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [PrivacyComponent, AboutUsComponent, TacComponent, ContactUsComponent, HelpComponent, NotFoundPageComponent, ServerErrorPageComponent, HowToEarnCashbackComponent, UnAuthorizedComponent],
  imports: [
    CommonModule,
    SharedModuleModule,
    StaticRoutingModule,
    FormsModule
  ]
})
export class StaticModule { }
