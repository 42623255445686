import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';
import { CommonService } from 'src/app/core/service/common.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { LoginResponse } from 'src/app/core/models/login-response.model';
import { VerificationOTP } from 'src/app/core/models/opt.model';

@Component({
  selector: 'app-mobile-verification',
  templateUrl: './mobile-verification.component.html',
  styleUrls: ['./mobile-verification.component.css']
})
export class MobileVerificationComponent implements OnInit {
  step = 1;
  user: LoginResponse;
  verification: VerificationOTP = {};
  err: any;
  bankVerification = false;
  first: string;
  second: string;
  third: string;
  forth: string;
  fifth: string;
  sixth: string;

  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  constructor(private _commonService: CommonService, private authSerivce: AuthService) { 
    
  }

  ngOnInit(): void {
    this.authSerivce.currentUser.subscribe({next: data => this.user=data});
  }

  sendOTP(): void {
    this.step = 2
  }

  resendOTP(evet): void {
    this._commonService.sendOTP().subscribe({
      next: data => this.countdown.restart
    });
}

  verifiyOTP(): void {
    this.verification.otp = this.first
                                .concat(this.second)
                                .concat(this.third)
                                .concat(this.forth)
                                .concat(this.fifth)
                                .concat(this.sixth);
    this._commonService.verifyOTP(this.verification).subscribe({
      next: data => {
        this.authSerivce.verifyCustomer();
        this.step = 3;
      },
      error: data => this.err = data.message[0]  
    });
  }

  get maskPhone() {
   const phone = this.user.user.customer.mobile_number;
   const phoneLength = phone.length;
   return "*".repeat(phoneLength-4).concat(phone.substring(phoneLength-4, phoneLength));
  }

  keytab(event: KeyboardEvent, maxLength){

    const myEl = <HTMLElement>event.srcElement;
    const nextElement = <HTMLElement>myEl.nextElementSibling;
    const previousElement = <HTMLElement>myEl.previousElementSibling;

  
    if (nextElement && event.key.length === maxLength) {
      nextElement.focus();
    } 
    // else{
    //   previousElement.focus();
    // }
    
  }

}

function MD_DIALOG_DATA(MD_DIALOG_DATA: any) {
  throw new Error('Function not implemented.');
}

