import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Bank } from 'src/app/core/models/bank.model';
import { CustomerBank } from 'src/app/core/models/customer.bank.model';
import { BanksService } from 'src/app/core/service/banks.service';

@Component({
  selector: 'app-add-local-bank',
  templateUrl: './add-local-bank.component.html',
  styleUrls: ['./add-local-bank.component.css']
})
export class AddLocalBankComponent implements OnInit {
  @ViewChild('closeModel') closeModel: ElementRef;
  @Output('reload') reload = new EventEmitter<boolean>();
  customerBank : CustomerBank = {};
  banks : Bank [] = [];
  error : string;
  constructor(private bankService: BanksService, private toastService: ToastrService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this._retrieveBanks();
  }

  private _retrieveBanks(): void {
    this.bankService.retrieveBanks().subscribe({
      next: data => this.banks = data.filter(b => !b.is_electronic_bank)
    })
  }

  addBank() : void {
    const selectBank = Number(this.customerBank.bank);
    const bank = this.banks.find(x => x.pk === selectBank);
    this.customerBank.bank = bank;
    this.bankService.addCustomerBank(this.customerBank).subscribe({
      next: data => {
        this.toastService.success(this.translateService.instant('message.bank-add-successfully'));
        this.reload.emit(true);
        this.closeModel.nativeElement.click();
      
      },
      error: err => err.details.forEach(element => {
        this.error = element;
      })
    });
    
  }
}
