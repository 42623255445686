import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';
import { StoreService } from 'src/app/core/service/store.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stores-by-categories',
  templateUrl: './stores-by-categories.component.html',
  styleUrls: ['./stores-by-categories.component.css']
})
export class StoresByCategoriesComponent implements OnInit {

  stores : Store [] = [];
  previous : string;
  next: string;
  count: number
  constructor(private storeService : StoreService, private route: ActivatedRoute) {
   }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this._retrieveStoreByCategoryId(paramMap.get('slug'));
    }); 
  }

  private _retrieveStoreByCategoryId (category) : void {
    this.storeService.retrieveStoreByCategory(category).subscribe((data) => {
      this.stores = data.results;
      this.next = data.next;
      this.previous = data.previous;
      this.count = data.count;
    })
  }

  onScroll() : void {
    if(this.next === null || this.next === undefined) return;    
    this.storeService.retrieveStoreByCategoryAndPaginationLink(this.next).subscribe((data) => {
      this.stores.concat(data.results);
      this.next = data.next;
      this.previous = data.previous;
      this.count = data.count;
    })
  }

}
