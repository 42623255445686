<div class="container clearfix">

    <div class="row gutter-40 col-mb-80">
        <!-- Post Content
        ============================================= -->
        <div class="terms">
            <h3 >Terms &amp; Conditions</h3>
            <p [innerHTML]="terms?.content_en"></p>
        </div>

            
            

        </div><!-- .postcontent end -->

        

    </div>
<app-mobile-app-component></app-mobile-app-component>