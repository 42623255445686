<section class="help-banner-wrapper">
  <div class="container">
    <div class="help-banner py-5">
      <div class="row">
        <div class="col-10 col-lg-5 offset-1">
          <div class="help-img-txt">
            <h4 class="mb-3" i18n>CashiBack</h4>
            <h2 class="mb-0" i18n>Shop through Cashiback at
              your favorite stores.</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>




<section class="help-tabs section-gap mt-5">
  <div class="container">
    <div class="row">
      <div class="col-10 col-lg-3 mb-5 mb-lg-0 mx-auto">
        <div class="help-topic-wrapper">
          <div class="border-bottom help-topic d-flex align-items-center" i18n>
            <i class="fal fa-bars me-2 fa-lg me-3"></i>
            <h5 class="mb-0">Help Topics</h5>
          </div>
          <div class="list-group" id="list-tab" role="tablist">
            <a class="list-group-item list-group-item-action active" id="list-refer-list" data-toggle="list"
              href="#list-refer" role="tab" aria-controls="refer" aria-selected="true" i18n>Refer &amp;
              Earn</a>
            <a class="list-group-item list-group-item-action" id="list-t-question-list" data-toggle="list"
              href="#list-t-question" role="tab" aria-controls="t-question" aria-selected="false" i18n>Top
              Questions</a>
            <a class="list-group-item list-group-item-action" id="list-privacy-list" data-toggle="list"
              href="#list-privacy" role="tab" aria-controls="privacy" aria-selected="false" i18n>Privacy
              &amp;
              Security</a>
            <a class="list-group-item list-group-item-action" id="list-general-q-list" data-toggle="list"
              href="#list-general-q" role="tab" aria-controls="general-q" aria-selected="false" i18n>General
              Questions</a>
            <a class="list-group-item list-group-item-action" id="list-account-q-list" data-toggle="list"
              href="#list-account-q" role="tab" aria-controls="account-q" aria-selected="false" i18n>Account
              Questions</a>
            <a class="list-group-item list-group-item-action" id="list-contact-list" data-toggle="list"
              href="#list-contact" role="tab" aria-controls="contact" aria-selected="true" *ngIf="loggedInUser">Contact
              us</a>
          </div>
        </div>

      </div>
      <div class="col-10 col-lg-9 mx-auto">
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade active show" id="list-refer" role="tabpanel" aria-labelledby="list-refer-list">
            <h2 i18n>Refer &amp; Earn</h2>
            <ng-container *ngFor="let q of faq">
              <h3 *ngIf="q.section === faqType.REFER">{{q.question_en}}</h3>
              <p *ngIf="q.section === faqType.REFER">{{q.answer_en}}</p>

            </ng-container>

          </div>
          <div class="tab-pane fade" id="list-t-question" role="tabpanel" aria-labelledby="list-t-question-list">
            <h2 i18n>Top Questions</h2>
            <ng-container *ngFor="let q of faq">
              <div class="faq-wrapper">
                <h3 *ngIf="q.section === faqType.TQ">{{q.question_en}}</h3>
                <p *ngIf="q.section === faqType.TQ">{{q.answer_en}}</p>
            </div>
            </ng-container>

          </div>
          <div class="tab-pane fade" id="list-privacy" role="tabpanel" aria-labelledby="list-privacy-list">
            <h2 i18n>Privacy &amp; Security</h2>
            <ng-container *ngFor="let q of faq">
              <h3 *ngIf="q.section === faqType.PS">{{q.question_en}}</h3>
              <p *ngIf="q.section === faqType.PS">{{q.answer_en}}</p>

            </ng-container>

          </div>
          <div class="tab-pane fade" id="list-general-q" role="tabpanel" aria-labelledby="list-general-q-list">
            <h2 i18n>General Questions</h2>

            <ng-container *ngFor="let q of faq">
              <h3 *ngIf="q.section === faqType.GQ">{{q.question_en}}</h3>
              <p *ngIf="q.section === faqType.GQ">{{q.answer_en}}</p>

            </ng-container>

          </div>
          <div class="tab-pane fade" id="list-account-q" role="tabpanel" aria-labelledby="list-account-q-list">
            <h2 i18n>Account Questions</h2>
            <ng-container *ngFor="let q of faq">
              <h3 *ngIf="q.section === faqType.AQ">{{q.question_en}}</h3>
              <p *ngIf="q.section === faqType.AQ">{{q.answer_en}}</p>

            </ng-container>
          </div>
          <div class="tab-pane fade" id="list-contact" role="tabpanel" aria-labelledby="list-contact-list">
            <div class="contact-us-tab">
              <h2>Contact us</h2>
              <div class="contact">
                <h4 class="main-title">Hi <span class="username">{{loggedInUser.first_name}}</span>,
                  How can we help
                  you today?</h4>
                <p class="sub-title">Fill out all the form fields</p>
                <form class="list-contact-form">
                  <div class="form-group">
                    <label for="helpContactTopis">Select a Topic</label>
                    <select class="form-control" id="helpContactTopis" [(ngModel)]="contactUs.subject"
                      #subject="ngModel" name="subject">
                      <option selected value="">Select a Topic</option>
                      <option value="promotionsQuestions">Email Promotions Questions</option>
                      <option value="changeEmail">Change Email Address</option>
                      <option value="trackCashOut">Track My CashOut</option>
                      <option value="storeFeedback">Store Feedback</option>
                      <option value="trackCashBack">Track My CashBack</option>
                      <option value="trackBounce">Track My Welcome Bounce</option>
                      <option value="cashiBackFeedback">CashiBack Feedback</option>
                      <option value="resetPassword">Reset My Password</option>
                      <option value="referIssue">Refer-A-Friend Issue</option>
                    </select>
                  </div>
                  <div class="topics-details">
                    <!-- Email Promotions Questions Topic -->
                    <div class="promotionsQuestions ">



                      <div class="form-row" *ngIf="contactUs.subject === 'trackCashBack'">
                        <div class="col-12 col-md-6 form-group">
                          <label for="">Order Number</label>
                          <input type="text" class="form-control" placeholder="Order Number">
                        </div>
                        <div class="col-12 col-md-6 form-group">
                          <label for="">Order Subtotal</label>
                          <input type="text" class="form-control" placeholder="Order Subtotal">
                        </div>
                        <div class="col-12 col-md-6 form-group">
                          <label for="exampleFormControlSelect1">Store Name </label>
                          <select class="form-control muted-select" id="exampleFormControlSelect1">
                            <option selected value="0">Store Name</option>
                            <option value="1"> 1</option>
                            <option value="2"> 2</option>
                            <option value="3"> 3</option>
                            <option value="4"> 4</option>
                          </select>
                        </div>
                        <div class="col-12 col-md-6 form-group">
                          <label for="exampleFormControlSelect1">Transaction Date </label>
                          <select class="form-control muted-select" id="exampleFormControlSelect1">
                            <option selected value="0">Transaction Date</option>
                            <option value="1"> 1</option>
                            <option value="2"> 2</option>
                            <option value="3"> 3</option>
                            <option value="4"> 4</option>
                          </select>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="detailsTextarea">Details</label>
                        <textarea name="detailsTextarea" rows="7"
                          placeholder="Please provide you detailed question here...."></textarea>

                      </div>

                      <a href="#" class="main-btn">Send<i class="fal fa-arrow-right"></i></a>
                    </div>


                    <!--
                                    <div class="trackCashBack hide-topic">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Reason </label>
                                            <select class="form-control" id="exampleFormControlSelect1">
                                                <option selected value="0">Shopping Trip</option>
                                                <option value="1">Method 1</option>
                                                <option value="2">Method 2</option>
                                                <option value="3">Method 3</option>
                                                <option value="4">Method 4</option>
                                            </select>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-12 col-md-6 form-group">
                                                <label for="">Order Number</label>
                                                <input type="text" class="form-control" placeholder="Order Number">
                                            </div>
                                            <div class="col-12 col-md-6 form-group">
                                                <label for="">Order Subtotal</label>
                                                <input type="text" class="form-control" placeholder="Order Subtotal">
                                            </div>
                                            <div class="col-12 col-md-6 form-group">
                                                <label for="exampleFormControlSelect1">Store Name </label>
                                                <select class="form-control muted-select" id="exampleFormControlSelect1">
                                                    <option selected value="0">Store Name</option>
                                                    <option value="1"> 1</option>
                                                    <option value="2"> 2</option>
                                                    <option value="3"> 3</option>
                                                    <option value="4"> 4</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-md-6 form-group">
                                                <label for="exampleFormControlSelect1">Transaction Date </label>
                                                <select class="form-control muted-select" id="exampleFormControlSelect1">
                                                    <option selected value="0">Transaction Date</option>
                                                    <option value="1"> 1</option>
                                                    <option value="2"> 2</option>
                                                    <option value="3"> 3</option>
                                                    <option value="4"> 4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="detailsTextarea">Details</label>
                                            <textarea name="detailsTextarea" cols="30" rows="7" placeholder="Paste the text of your order confirmation from the store here. we must be able to view this information in order to research your inquiry. Copy (ctr+c) and paste (ctr+v) the order confirmation."></textarea>

                                        </div>
                                        <a href="#" class="main-btn">Track My Cash Back<i class="fal fa-arrow-right"></i></a>
                                    </div> -->




                  </div>
                </form>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</section>
