import { Component, OnInit, Input } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';
import { LoginResponse } from 'src/app/core/models/login-response.model';
import { StoreService } from 'src/app/core/service/store.service';

@Component({
  selector: 'app-store-detail-header',
  templateUrl: './store-detail-header.component.html',
  styleUrls: ['./store-detail-header.component.css']
})
export class StoreDetailHeaderComponent implements OnInit {

  cdn = environment.cdn;
  isAuthorized = false;
  user: LoginResponse;
  @Input() store:Store;
  constructor(private router: Router, private authService: AuthService, private storeService: StoreService) { }

  ngOnInit() {
    this.isAuthorized= this.authService.isUserLoggedIn();
  }

  redirect() : void {
    // this.router.navigate(['/stores', this.store.pk, 'redirect']);
    window.open("/stores/" + this.store.pk + '/redirect', "_blank");
  }

  get getCurrencyCode() : string {
      return this.user?.user?.customer?.currency.code || 'SAR';
  }

  accessDenied(): void{
    this.router.navigate(['/','access-denined'])
  }

  addOrRemoveFav() : void {
    if (this.store.is_fav) {
      this.storeService.removeFavStore(this.store.pk).subscribe(data => this.store.is_fav = false);
    } else {
      this.storeService.addFavStore(this.store.pk).subscribe(data => this.store.is_fav = true);      
    }    
  }

}
