import { Component, OnInit } from '@angular/core';
import { TermsAndCondition } from 'src/app/core/models/terms-and-condition.model';
import { CommonService } from 'src/app/core/service/common.service';

@Component({
  selector: 'app-tac',
  templateUrl: './tac.component.html',
  styleUrls: ['./tac.component.css']
})
export class TacComponent implements OnInit {

  terms : TermsAndCondition;
  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this._retrieveTAC();
  }

  private _retrieveTAC() : void {
    this.commonService.retrieveTAC().subscribe(data => this.terms = data);
  }
}
