import { Component, OnInit } from '@angular/core';
import { Bank } from 'src/app/core/models/bank.model';
import { CustomerBank } from 'src/app/core/models/customer.bank.model';
import { BanksService } from 'src/app/core/service/banks.service';

@Component({
  selector: 'app-add-online-bank',
  templateUrl: './add-online-bank.component.html',
  styleUrls: ['./add-online-bank.component.css']
})
export class AddOnlineBankComponent implements OnInit {
  customerBank : CustomerBank = {};
  banks : Bank [] = [];
  error : string;
  constructor(private bankService: BanksService) { }

  ngOnInit(): void {
    this._retrieveBanks();
  }

  private _retrieveBanks(): void {
    this.bankService.retrieveBanks().subscribe({
      next: data => this.banks = data.filter(b => b.is_electronic_bank)
    })
  }

  close():void {
  }

  addBank() : void {
    const selectBank = Number(this.customerBank.bank);
    const bank = this.banks.find(x => x.pk === selectBank);
    this.customerBank.bank = bank;
    this.bankService.addCustomerBank(this.customerBank).subscribe({
      error: err => err.details.forEach(element => {
        this.error = element;
      })
    });
    
  }
}
