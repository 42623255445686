import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/core/service/profile.service';
import { Referral } from 'src/app/core/models/referral.model';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-earn-and-refer',
  templateUrl: './earn-and-refer.component.html',
  styleUrls: ['./earn-and-refer.component.css']
})
export class EarnAndReferComponent implements OnInit {

  referral : Referral;
  constructor(private profileService: ProfileService, private route:ActivatedRoute, private router:Router, private cookieService: CookieService) { 
    
  }

  ngOnInit() {
    this.retrieveReferrals();
  }

  retrieveReferrals() : void {
    this.profileService.retrieveReferral()
      .subscribe(data => this.referral = data);
  }

  private _setReferralCookie(code: string):void {
    localStorage.set('referral', code);
    this.router.navigate(['/']);
  }

  get referalCode () {
    return window.location.protocol + '//' + window.location.hostname + '?referral_code=' + this.referral.url;
  }

  get pending() {
    return this.referral.pending * this.referral.rule.value;
  }

  get success() {
    return this.referral.success * this.referral.rule.value;
  }

  get total() {
    return this.referral.total * this.referral.rule.value;
  }

}
