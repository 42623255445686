<p-table [value]="cashback"  [paginator]="true" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll" 
currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]"
[globalFilterFields]="['pk','trx_date','store.name_en']">
   
    <ng-template pTemplate="header">
        <tr>
            <th>Date</th>
            <th>Store</th>
            <th>Available</th>
            <th>Amount</th>
            <th>Status</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-c>
        <tr>
            <td>{{c.trx_date | date: 'medium'}}</td>
            <td>
                <img [src]="cdn + c.order.store.logo"  width="80" style="vertical-align: middle" alt="{{c.order.store.name_en}}">

            </td>
            <td>{{c.ready_to_use}}</td>
            <td>{{c.cashback_amount | currency}}</td>
            <td>{{c.status}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="7">No cashback .</td>
        </tr>
    </ng-template>

</p-table>