import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInCustomerGuard } from './core/logged-in-customer.guard';

const routes: Routes = [
  {path: '', loadChildren: () => import('./module/home/home.module').then(m => m.HomeModule)},
  {path: 'stores', loadChildren: () => import('./module/stores/stores.module').then(m => m.StoresModule)},
  {path: 'referrals', loadChildren: () => import('./module/earn-and-refer/earn-and-refer.module').then(m => m.EarnAndReferModule), canActivate:[LoggedInCustomerGuard]},
  {path: 'search', loadChildren: () => import('./module/search/search.module').then(m => m.SearchModule)},
  {path: 'profile', loadChildren: () => import('./module/profiles/profiles.module').then(m => m.ProfilesModule), canActivate: [LoggedInCustomerGuard]},
  {path: 'auth', loadChildren: () => import('./module/auth/auth.module').then(m => m.AuthModule)},
  {path: '', loadChildren: () => import('./module/static/static.module').then(m => m.StaticModule)},


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
