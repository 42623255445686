import { Component, OnInit } from '@angular/core';
import { CampaignService } from 'src/app/core/service/campaign.service';
import { CustomerCampaign } from 'src/app/core/models/customer-campaign.model';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {

  customerCampaigns : CustomerCampaign [] = [];
  constructor(private _campaignService:CampaignService) { }

  ngOnInit() {
    this._retrieveCustomerCampaign()
  }

  private _retrieveCustomerCampaign(): void {
    this._campaignService.retrieveCampagin().subscribe({
      next: data => {
        this.customerCampaigns= data.results;

        console.log(data);
        
      }
    })
  }
}
