import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClientService } from '../http-client/api-client.service';
import { WithdrawalRequest } from '../models/withdrawal-req.model';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalService {

  constructor(private apiClient: ApiClientService) { }

  createWithdrawal(withdrawalRequest: WithdrawalRequest): Observable<any> {
    return this.apiClient.postRequest<Observable<any>>(`withdrawal`, withdrawalRequest);
  }
}
