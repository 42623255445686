<section class="section-gap">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="main-title mb-3" i18n>My Favorite Stores</h1>
        <p class="secondary-text mb-5">Check out today's Top Stores offering extra
          Cash Back &amp; special discounts.</p>
      </div>
    </div>
    <div class="row row-cols-2 row-cols-md-3 row-cols-lg-5" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()">
      <app-store-card class="col mb-3" *ngFor="let store of stores" [store]="store" [isFav]="true"></app-store-card>
    </div>
  </div>

</section>

<app-mobile-app-component></app-mobile-app-component>
