<section class="products-cards newly section-gap">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 text-center text-lg-start mb-3 mb-lg-0">
        <h2 class="mb-3" i18n>Sepcial offers for you</h2>
        <p class="text-secondary h6" i18n>Shop Todays Trending Deals and Save Big </p>
      </div>
      <div class="col-lg-3 text-center text-lg-end">
        <button class="main-btn" i18n>Show All <i class="far fa-arrow-right ms-1"></i>
        </button>
      </div>
      <div class="col-12">
        <app-promotion-card *ngFor="let c of customerCampaigns" [camp]="c"
          class='col-lg-3  col-md-6 col-sm-12'></app-promotion-card>
      </div>
    </div>
  </div>


</section>