<div class="col-lg-9 text-center text-lg-start mb-3 mb-lg-0">
  <h2 i18n class="mb-3">Cashback from Top-Selling Stores</h2>
  <p class="text-secondary h6" i18n>Discover more than 10 categories</p>
</div>
<div class="col-lg-3 text-center text-lg-end">
  <a href="#" routerLink="stores/top" class="main-btn float-lg-end" i18n>
    Show All
    <i class="fal fa-arrow-right ms-1" aria-hidden="true"></i>
  </a>
</div>
