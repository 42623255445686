<section class="category-tabs mt-5 ">
  <h1 class="section-title mb-4"><i class="far fa-clipboard-list me-3"></i> {{store?.name_en}} Coupons and promocodes
    Cash Back</h1>
  <div id="myBtnContainer" class="category-tabs-header mb-3 px-4">
    <button class="btn active" onclick="filterSelection('all')"> All (30)</button>
    <button class="btn" onclick="filterSelection('deals')"> Deals</button>
    <button class="btn" onclick="filterSelection('coupons')">Coupons</button>
  </div>

  <!-- Portfolio Gallery Grid -->
  <div class="offers-wrapper">
    <div class="offer-box">
      <div class="row">
        <div class="col-lg-9 mb-5 mb-lg-0 text-center text-lg-start">
          <div class="offer-details">
            <div class="offer-details-header">
              <span class="offer-kind"><i class="far fa-ticket-alt me-3"></i> Coupon</span>
            </div>
            <h5 class="offer-title">
              $5 off Promo code for new users on Foodpanda when you order with Foodpanda!
            </h5>
            <p class="cashback-percent">Up to 2% Cash Back</p>
          </div>
        </div>
        <div class="col-lg-3 d-flex align-items-center justify-content-center justify-content-lg-end">
          <button class="main-btn">Get Code</button>
        </div>
      </div>
    </div>

    <div class="offer-box">
      <div class="row">
        <div class="col-lg-9 mb-5 mb-lg-0 text-center text-lg-start">
          <div class="offer-details">
            <div class="offer-details-header">
              <span class="offer-kind"><i class="far fa-tag me-3"></i>Deals</span>
              <span class="expire-time ms-5">
                <div class="icon-wrapper me-2">
                  <i class="far fa-stopwatch"></i>
                </div>
                Expires 7/22/2020
              </span>
            </div>
            <h5 class="offer-title">
              Indonesian Food: Spice it up with Foodpanda on your favourite Indonesian dishes!
            </h5>
            <p class="cashback-percent">Up to 2% Cash Back</p>
          </div>

        </div>
        <div class="col-lg-3 d-flex align-items-center justify-content-center justify-content-lg-end">
          <button class="main-btn">Shop Now</button>
        </div>
      </div>
    </div>

    <div class="offer-box">
      <div class="row">
        <div class="col-lg-9 mb-5 mb-lg-0 text-center text-lg-start">
          <div class="offer-details">
            <div class="offer-details-header">
              <span class="offer-kind"><i class="far fa-tag me-3"></i>Deals</span>
            </div>
            <h5 class="offer-title">
              Indonesian Food: Spice it up with Foodpanda on your favourite Indonesian dishes!
            </h5>
            <p class="cashback-percent">Up to 2% Cash Back</p>
          </div>
        </div>
        <div class="col-lg-3 d-flex align-items-center justify-content-center justify-content-lg-end">
          <button class="main-btn">Shop Now</button>
        </div>
      </div>
    </div>
  </div>
</section>