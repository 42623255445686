<div class="modal-register" *ngIf="step === 1" id="modal-unverified">
    <div class="card mx-auto" style="max-width: 600px;">
      <!-- Modal Header-->
      <div class="modal-unverified">
        <div class="unverified-header-icon">
          <img src="../../../../assets/images/unverified/speech-bubble-white.png">
        </div>
      <!-- Modal Body-->
        <div class="modal-card-body">
            <h4 i18n>Your Account is unverified!</h4>
            <p i18n> Please verify your account in order to be able to check stores!</p>
        </div>
      </div>
      <!-- Modal footer for signup bottom-->
      <div class="model-footer">
         <button href="#" data-lightbox="inline" class="btn btn-danger" (click)="sendOTP()" i18n>Verify Account</button> <br>
      </div>
    </div>
</div>

<div class="modal-register" *ngIf="step === 2" id="modal-verification">
    <div class="card mx-auto" style="max-width: 600px;">
      <!-- Modal Header-->
      <div class="card-header py-3">
        <div class="header-icon">
            <i class="fas fa-mobile-alt" aria-hidden="true"></i>
        </div>
        <h2 i18n>Verification Code</h2>
        <p i18n>Code is sent to <span style="color: #f05864;">{{maskPhone}}</span></p>
      </div>
      <!-- Modal Body-->
      <form #f="ngForm" (ngSubmit)="f.form.valid && verifiyOTP()" novalidate>
        <div class="error-message" *ngIf="err">
          <i class="far fa-check-circle" aria-hidden="true"></i> {{err}}
        </div>
        <div class="modal-card-body">
          
            
                <input type="text" name="num1"  [(ngModel)]="first" (keyup)="keytab($event, 1)" #num1="ngModel" required  minlength="1" maxlength="1" tabindex="1" autocomplete="off" [ngClass]="(f.submitted && num1.invalid) ? 'unverified' : ''"/>
                <input type="text" name="num2"  [(ngModel)]="second" (keyup)="keytab($event, 1)" #num2="ngModel" required maxlength="1" minlength="1" tabindex="2" autocomplete="off" [ngClass]="(f.submitted && num2.invalid) ? 'unverified' : ''"/>
                <input type="text" name="num3"  [(ngModel)]="third" (keyup)="keytab($event, 1)" #num3="ngModel" required maxlength="1" minlength="1" tabindex="3" autocomplete="off" [ngClass]="(f.submitted && num3.invalid) ? 'unverified' : ''"/>
                <input type="text" name="num4"  [(ngModel)]="forth" (keyup)="keytab($event, 1)" #num4="ngModel"  required maxlength="1" minlength="1" tabindex="4" autocomplete="off" [ngClass]="(f.submitted && num4.invalid) ? 'unverified' : ''"/>
                <input type="text" name="num5"  [(ngModel)]="fifth"  (keyup)="keytab($event, 1)" #num5="ngModel" required maxlength="1" minlength="1" tabindex="5" autocomplete="off" [ngClass]="(f.submitted && num5.invalid) ? 'unverified' : ''"/>
                <input type="text" name="num6"  [(ngModel)]="sixth" (keyup)="keytab($event, 1)" #num6="ngModel" required maxlength="1" minlength="1" tabindex="6" autocomplete="off" [ngClass]="(f.submitted && num6.invalid) ? 'unverified' : ''"/> 
            
        </div>

        <p i18n *ngIf="f.submitted && (num1.invalid || num2.invalid || num3.invalid || num4.invalid || num5.invalid || num6.invalid) && ((num1.errors.required || num2.errors.required || num3.errors.required || num4.errors.required || num5.errors.required || num6.errors.required) )" class="incorrect-code">
          The Code you entered is required.
        </p>
        <!-- Modal footer for signup bottom-->
        <div class="model-footer">
        

            <p i18n>Resend Code  <countdown style="color: #f05864" #cd [config]="{leftTime: 60, format: 'm:s' }" (event)="resendOTP($event)"></countdown></p>
            <button  data-lightbox="inline" class="btn btn-danger" i18n>Verify</button> 
            
        </div>
        </form>
    </div>
</div>


<div class="modal-register" id="modal-verified" *ngIf="step === 3">
        <div class="card mx-auto" style="max-width: 600px;">
          <!-- Modal Header-->
          <div class="modal-verified">
            <div class="verified-header-icon">
              <img src="../../../../assets/images/unverified/check.png">
            </div>
          <!-- Modal Body-->
            <div class="modal-card-body">
                <h4 i18n>Your Account is Verified Successfully!</h4>
            </div>
          </div>
          <!-- Modal footer for signup bottom-->
          <div class="model-footer">
            
          </div>
        </div>
    </div>