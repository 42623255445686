<section class="contact-page section-gap">
  <div class="container">
    <div class="title-wrapper">
      <h3 class="title" i18n>How can we help you today?</h3>
      <p class="sub_title" i18n>Fill out all the form fields</p>
    </div>

    <form class="row g-3 mt-5">
      <div class="col-12">
        <label for="inputState" class="form-label">Select a Topic</label>
        <select id="inputState" class="form-select">
          <option selected>Choose...</option>
          <option>Email Promotions Questions</option>
          <option>Email Promotions Questions</option>
          <option>Email Promotions Questions</option>
          <option>Email Promotions Questions</option>
        </select>
      </div>
      <div class="col-12 mt-5">
        <label for="exampleFormControlTextarea1" class="form-label">Details</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="7" placeholder="Details"></textarea>
      </div>
      <div class="col-12 mt-5">
        <button type="submit" class="main-btn">Send <i class="fal fa-arrow-right ms-3"></i></button>
      </div>
    </form>

  </div>
</section>
<app-mobile-app-component></app-mobile-app-component>