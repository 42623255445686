import { Component, OnInit } from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-server-error-page',
  templateUrl: './server-error-page.component.html',
  styleUrls: ['./server-error-page.component.css'],

})
export class ServerErrorPageComponent implements OnInit {
  constructor(private _router:Router) {}

  ngOnInit(): void {
  }

  reload(): void {
    this._router.navigate(['/']);
  }

}
