import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-cashback-overview',
  templateUrl: './cashback-overview.component.html',
  styleUrls: ['./cashback-overview.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CashbackOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
