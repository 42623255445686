<input type="radio" id="bank-id-{{bank.pk}}" name="bankId" hidden (click)="pushBank(bank.pk)">
<label for="bank-id-{{bank.pk}}">
  <div class="card-details" *ngIf="bank" #bank>
    <div class="verification-panel unverified" *ngIf="!bank?.verified">
      Bank is unverified!

      <a href="#" data-toggle="modal" data-target="#verificationModal">Click here for verification.</a>
    </div>
    <div class="verification-panel verified" *ngIf="bank?.verified">
      Bank is verified!
    </div>
    <div class="card-information">
      <div class="dropdown setting-dropdown">
        <a class="d-flex" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
          <i class="fal fa-cog"></i>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <a class="dropdown-item" (click)="deleteBank(bank.pk)"><i class="fal fa-trash-alt"></i>
            Delete</a>

        </div>
      </div>
      <div class="bank-logo">
        <img [src]="logo">
      </div>
      <div class="card-number" *ngIf="!isElectron">
        <span>****</span>
        <span>****</span>
        <span>****</span>
        <span>{{maskAccountNumber}}</span>
      </div>

      <div class="card-number" *ngIf="isElectron">
        <span>{{accountNumber}}</span>
      </div>

      <h6 class="card-user-name">{{accountName || '--'}}</h6>
    </div>
  </div>
</label>

<!-- Modal -->
<div class="modal fade verificationModal" id="verificationModal" tabindex="-1" aria-labelledby="verificationModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title-upper">
          <div class="title-icon"><i class="fal fa-credit-card-front"></i>
          </div>
          <h5 id="Verification-Code" class="modal-title">Verification Code</h5>
        </div><button type="button" data-dismiss="modal" aria-label="Close" class="close"><i
            aria-hidden="true" class="fal fa-times"></i></button>
      </div>
      <div class="modal-body">
        <p class="mb-1">We sent verification Code to <span style="color: #f05864;">0595xxxxx22</span> please enter the code below</p>
        <p class="mb-3">6-digits code *</p>
        <form class="codeNumFields">
          <input type="text" name="num1" maxlength="1" required>
          <input type="text" name="num1" maxlength="1" required>
          <input type="text" name="num1" maxlength="1" required>
          <input type="text" name="num1" maxlength="1" required>
          <input type="text" name="num1" maxlength="1" required>
          <input type="text" name="num1" maxlength="1"required>
        </form>
      </div>
      <div class="modal-footer flex-column">
        <button type="submit" class="btn btn-primary main-btn">Verify</button>
        <p class="text-dark mt-3">Didn't receive an message? <a href="#" class="fw-bold">Try again</a></p>
      </div>
    </div>
  </div>
</div>
