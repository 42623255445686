<h1 class="main-header-title withdraw-title mb-0"><i class="fal fa-envelope-open-dollar"></i>
  Cashback Overview</h1>

<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <a class="nav-link active pt-0 px-0" id="cashback-activity-tab" data-toggle="tab" href="#cashback-activity"
      role="tab" aria-controls="cashback-activity" aria-selected="true">Cashback Activity</a>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link pt-0 px-0" id="withdrawal-history-tab" data-toggle="tab" href="#withdrawal-history" role="tab"
      aria-controls="withdrawal-history" aria-selected="false">Withdrawal History</a>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link pt-0 px-0" id="click-activity-tab" data-toggle="tab" href="#click-activity" role="tab"
      aria-controls="click-activity" aria-selected="false">Click Activity</a>
  </li>

</ul>
<div class="tab-content cashback-overview" id="myTabContent">
  <app-cashback-activity class="tab-pane fade show active" id="cashback-activity" role="tabpanel" aria-labelledby="cashback-activity-tab"></app-cashback-activity>


  <app-withdrawal-history class="tab-pane fade" id="withdrawal-history" role="tabpanel" aria-labelledby="withdrawal-history-tab"></app-withdrawal-history>
  <app-click-activity class="tab-pane fade" id="click-activity" role="tabpanel" aria-labelledby="click-activity-tab"></app-click-activity>
  

</div>

