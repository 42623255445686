<section class="privacy-policy section-gap">
  <div class="container">
    <div class="row">
      <!-- Post Content
        ============================================= -->
      <div class="privacy">
        <h3>Privacy Policy</h3>
        <p>We at ShopBack respect the privacy and confidentiality of the personal data of our clients,
          customers, visitors and all others whom we interact with in the course of providing our services.
          While we are driven by the goal of providing customers with an enhanced shopping experience,
          we also understand that a key concern that customers may have is the safety of their personal information,
          and are committed to ensuring that all personal data provided to us remain secure and are only utilised for
          purposes that our customers have consented to.</p>
        <div>
          <h4>Personal Data Protection Policy</h4>
          <p>The purpose of this document is to inform you as to how ShopBack manages, collects, uses, and discloses
            Personal Data relating to you and other users
            of our website, extension for desktop web browsers (“web extension”), software applications, mobile
            applications and other platforms. We conduct our
            business in compliance with the relevant data protection legislation, and have implemented additional
            measures to protect your personal information. The continued provision of our services to you and your
            continued
            use of such services shall be deemed to be our mutual agreement to abide by this Personal Data Protection
            Policy as updated from time to time on our
            website.</p>


          <h4>Personal Data </h4>

          <p>In this Personal Data Protection Policy, “Personal Data” refers to any data, whether true or not, about an
            individual who can be identified from that data,
            or from that data and other information to which we have or are likely to have access, including data in our
            records as may be updated from time to
            time.</p>

          <h4>Collection of Personal Data</h4>

          <p>The Personal Data that ShopBack may collect from you will depend on the products, services, and promotions
            that you use or subscribe to. ShopBack
            collects this data to enable our services and our platforms to function properly and to help us better
            understand how they work on different devices and
            browsers. Typically, ShopBack will collect Personal Data relating to you when you:

          </p>
          <ul>
            <li>Leave your contact details with us after attending our events e.g. marketing or community events and
              seminars.
            </li>
            <li>Leave your contact details with us after visiting, using or installing (as the case may be) our
              websites, portals, mobile applications, social media platforms and web extension, or make enquiries via
              any other portal
            </li>
            <li>Register for our services and/or submit any forms relating to any of our products and services.
            </li>
            <li>Sign up for alerts or newsletters or respond to our marketing collaterals e.g. advertisements, brochures
              or flyers.
            </li>
            <li>Contact us with enquiries or requests for assistance or interact or communicate with us or our
              employees, e.g. via our online interface, email, telephone calls, faxes, text messages or face-to-face
              meetings.
            </li>
            <li>Participate in competitions, lucky draws, surveys, or other such events.
            </li>
            <li>Submit your CV and job application forms to us or submit your CV to recruitment firms or job portals,
              who in turn forward the same to us.
            </li>
            <li>Are referenced to us by business partners or third parties.
            </li>
          </ul>
          <p></p>
          <h4>The Personal Data collected may include information relating to your:</h4>
          <ul>
            <li>Identity: This includes your full name, e-mail address, date of birth, phone number, photographs, device
              ID, IP address, and payment-related information (e.g. bank account details or details of your account with
              a payment service provider such as PayPal).</li>
            <li>Interactions with us: This includes recordings of your phone calls with us, emails you sent, and other
              records of any contact you have with us.</li>
            <li>Account: Details relating to your ShopBack account.</li>
            <li>Use of our services: This includes using cookies, our web extension or other browser related methods to
              track information relating to your interaction with our and our merchants’ websites (e.g. the pages within
              our website that you visit, the time spent on those pages, items and information searched on our site,
              access time and dates, webpages you were visiting prior to our site, and information relating to clicks
              and redirects).</li>
            <li>For the avoidance of doubt however, ShopBack’s web extension &#8203;does not &#8203;track your search
              engine history, emails or your browsing data on any site that is not one of ShopBack’s merchant’s sites or
              where it is not related to ShopBack’s product or services. When you are on one of our merchant’s sites,
              ShopBack will only collect information about that site that lets us calculate and validate with the
              merchant the rewards due to you and such coupons or promos that are applicable to you. Please see below
              for a further explanation of how our web extension operates and what data it collects.</li>
            <li>Preferences: This includes details that you have shared with us as to how you would like to be
              contacted, and your preferred products and brands.</li>
            <li>Location: Some of the services we provide depend on your location. In order to work, those services need
              to know your location. Whenever you open and use or interact with our platforms (incl. our app) on your
              mobile device or otherwise, we use the location information to tailor our services to your location. The
              services use your various devices’ ‘background location’, including to send you notifications of offers /
              plans / interesting things near you. If you have ‘background location’ turned on, our platforms will from
              time to time tell us about your device’s location even if you are not directly interacting with our
              platforms.
            </li>
          </ul>

          <p>Apart from collecting the above Personal Data directly from you or your authorised representatives,
            ShopBack may also collect Personal Data from third
            parties (such as our business partners, Google, social media providers, or fraud-prevention agencies), or
            from publicly available sources.
            <br>
            In addition, ShopBack uses “cookies”, which are small data files sent to your browser to store and track
            information about you when you enter our
            website. The cookie is used to track some of the information mentioned above relating to the use of our
            services.
            <br>
            Most internet browsers provide you the option to turn off the processing of cookies but this may result in
            the loss of functionality, restrict your use of the
            website, and/or delay or affect the way in which it operates. In particular, it would not allow us to track
            your transactions with our merchant partners
            and this would mean that we would be unable to credit you with cashback or otherwise provide you with the
            products and services that you have
            requested. That said, you should configure our web extension and your use thereof.
          </p>

        </div>
      </div><!-- .postcontent end -->
    </div>
  </div>
</section>

<app-mobile-app-component></app-mobile-app-component>