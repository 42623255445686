import { Component, OnInit } from '@angular/core';
import { CustomerBank } from 'src/app/core/models/customer.bank.model';
import { Rules } from 'src/app/core/models/rules.model';
import { BanksService } from 'src/app/core/service/banks.service';
import { CommonService } from 'src/app/core/service/common.service';

@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.css']
})
export class WithdrawalComponent implements OnInit {
  onlineBanks : CustomerBank [] = [];
  localBanks : CustomerBank [] = [];
  minimumWithdrawalRule : Rules;
  maxWithdrawalPerMonthRule : Rules;
  
  constructor(private bankService: BanksService, private _commonService: CommonService) { }

  ngOnInit(): void {
    this._retrieveCustomerBanks();
    this._retrieveWithdrawalRules();
  }

  private _retrieveCustomerBanks(): void {
    this.bankService.retrieveCustomerBanks().subscribe(
      {
        next: data =>  {
          this.onlineBanks = data.filter(b => b.bank).filter(b => b.bank.is_electronic_bank);
          this.localBanks = data.filter(b => b.bank).filter(b => !b.bank.is_electronic_bank);
          console.table(this.localBanks);
        }
      }
    )
  }

  private _retrieveWithdrawalRules(): void {
    this._commonService.retrieveRules().subscribe({
      next: data => {
        this.minimumWithdrawalRule = data.find(x => x.type === 'MinimumWithdrawal');
        this.maxWithdrawalPerMonthRule = data.find(x => x.type === 'MaximumWithdrawal');
      }
    })
  }
}
