import { Component, Input, OnInit } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-store-card-second',
  templateUrl: './store-card-second.component.html',
  styleUrls: ['./store-card-second.component.css']
})
export class StoreCardSecondComponent implements OnInit {

  @Input() store : Store;
  cdn = environment.cdn;
  constructor() { }

  ngOnInit(): void {
  }

}
