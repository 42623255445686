<div class="container mb-5 reset-password">
    <div class="error-message" *ngIf="errorMessage">
        <i class="fas fa-check-circle" aria-hidden="true"></i> {{errorMessage}}
      </div>
    <div class="row container mb-3">
        <div class="col-lg-6 col-xs-9">
            <h2 i18n>Reset your password</h2>
            <p i18n>Minimum length is 8 characters containing letters &amp; numbers</p>
            <form #resetPasswordConfirmForm="ngForm" (ngSubmit)="resetPasswordConfirmForm.form.valid && doResetPasswordConfirm();" novalidate>
              <label i18n>New Password</label>
              <input type="password" i18n-placeholder placeholder="New Password" name="new_password1" [(ngModel)]="resetPasswordConfirm.new_password1" #new_password1="ngModel" required [ngClass]="(resetPasswordConfirmForm.submitted && new_password1.invalid) ? 'form-control invalid' : ''"/>
              <p *ngIf="resetPasswordConfirmForm.submitted && new_password1.invalid && new_password1.errors.required" class="invalid" i18n>
                Password is required
              </p>

              <label>Confirm New Password</label>
              <input type="password" i18n-placeholder placeholder="Confirm New Password" name="new_password2" [(ngModel)]="resetPasswordConfirm.new_password2" #new_password2="ngModel" required [ngClass]="(resetPasswordConfirmForm.submitted && new_password2.invalid) ? 'form-control invalid' : ''">
              <p *ngIf="resetPasswordConfirmForm.submitted && new_password2.invalid && new_password2.errors.required" class="invalid" i18n>
                Password is required
              </p>

              <button class="btn  btn-danger " type="submit" i18n>Reset your password</button>
            </form>
        </div>


    </div>
</div>