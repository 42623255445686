


    <div class="modal fade online-transfer-form" id="addBankForm" tabindex="-1" aria-labelledby="addBankFormLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <form class="add-bank-account mb-0" #f="ngForm" (ngSubmit)="f.form.valid && addBank()">

        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title-upper">
                    <div class="title-icon">
                        <i class="fal fa-credit-card-front"></i>
                    </div>
                    <h5 class="modal-title" id="addBankFormLabel">Add a Bank Account</h5>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModel >
                    <i class="fal fa-times" aria-hidden="true"></i>
                </button>
            </div>
            <div class="modal-body">

                    <div class="form-row">
                        <div class="offset-md-2 col-md-8">
                            <div class="form-group">
                                <label class="form-label">Bank</label>
                                <select class="form-control" [(ngModel)]="customerBank.bank" name="bank" #bank="ngModel">
                                    <option  *ngFor="let bank of banks" [value]="bank.pk">{{bank.name_en}} </option>

                                </select>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Bank Account No. (without hyphen or space)</label>


                                  <input type="text" class="form-control" placeholder="GB343 WEST 1234 5678 3322" #accountNumber="ngModel" name="accountNumber" [(ngModel)]="customerBank.account_number" required [autocomplete]="false">
                                  <p  *ngIf="f.submitted && accountNumber.invalid && accountNumber.errors.required" class="incorrect-code">
                                      Account number is required
                                    </p>

                            </div>
                            <div class="form-group">
                                <label class="form-label">Name as per Bank Account</label>

                                <input type="text"  class="form-control" placeholder="Abeer Hasan" #accountName="ngModel" name="accountName" [(ngModel)]="customerBank.account_name" required [autocomplete]="false" >
                                <p  *ngIf="f.submitted && accountName.invalid && accountName.errors.required" class="incorrect-code">
                                    Account name is required
                                  </p>
                            </div>
                            <div class="form-group">
                                <label class="form-label">IBAN</label>

                                <input type="text"  class="form-control" placeholder="FI 32 5000 873458349534" #swift="ngModel" name="swift" [(ngModel)]="customerBank.swift" required [autocomplete]="false" >
                                <p  *ngIf="f.submitted && swift.invalid && swift.errors.required" class="incorrect-code">
                                    IBAN is required
                                  </p>
                            </div>

                        </div>
                    </div>

            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary main-btn">Save Account</button>
            </div>
        </div>
    </form>
    </div>
</div>
