<p-table [value]="withdrawal"  [paginator]="true" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll" 
currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]"
[globalFilterFields]="['pk','trx_date','store.name_en']">
    <!-- <ng-template pTemplate="caption">
        <div class="flex">
            <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
            </span>
        </div>
    </ng-template> -->
    <ng-template pTemplate="header">
        <tr>
            <th>Date</th>
            <th>Bank</th>
            <th>Amount</th>
            <th>Status</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-c>
        <tr>
            <td>{{c.trx_date | date: 'medium'}}</td>
            <td>
                {{c.bank_name}}
            </td>
            <td>{{c.amount | currency}}</td>
            <td>
              <p-badge value="{{c.status | titlecase}}"  severity="info"></p-badge>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="7">No withdrawal.</td>
        </tr>
    </ng-template>

</p-table>