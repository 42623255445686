<section class="category-buttons ">
  <h1 class="section-title mb-4" i18n><i class="far fa-ballot me-3"></i> Cash back by category</h1>
  <div class="row">
    <div class="col-6 mb-3" *ngFor="let category of store.stores_categories | slice:0: endSlicing">
      <button class="btn-category">
        {{category.store_category_name | titlecase}} <span
          class="ms-3">{{category.customer_commission}}</span>
      </button>

    </div>
    <div class="col-6 mb-3"
      *ngFor="let category of store.stores_categories | slice:startSlicing: store.stores_categories.length">
      <button class="btn-category">
        {{category.store_category_name | titlecase}} <span
          class="ms-3">{{category.customer_commission}}</span>
      </button>
    </div>
  </div>
</section>