import { Component, Input, OnInit } from '@angular/core';
import { CustomerBank } from 'src/app/core/models/customer.bank.model';
import { LoginResponse } from 'src/app/core/models/login-response.model';
import { Rules } from 'src/app/core/models/rules.model';
import { WithdrawalRequest } from 'src/app/core/models/withdrawal-req.model';
import { AuthService } from 'src/app/core/service/auth.service';
import { BanksService } from 'src/app/core/service/banks.service';
import { WithdrawalService } from 'src/app/core/service/withdrawal.service';

@Component({
  selector: 'app-bank-withdrawal',
  templateUrl: './bank-withdrawal.component.html',
  styleUrls: ['./bank-withdrawal.component.css']
})
export class BankWithdrawalComponent implements OnInit {

  @Input() banks : CustomerBank[] = [];
  @Input() min : Rules;
  @Input() max : Rules;
  loggedInUser: LoginResponse ;
  withdrawal : WithdrawalRequest = {};
  successMessage: string;
  errorMessage: string;

  constructor(private authService: AuthService, private withdrawalService: WithdrawalService, private bankService:BanksService) { 
    this.authService.currentUser.subscribe(x => this.loggedInUser=x);

  }

  ngOnInit(): void {
  }

  openDialog() {
   
  }

  do() :void {
    this.withdrawalService.createWithdrawal(this.withdrawal).subscribe({
      next:data => this.successMessage = 'Withdrawal request completed',
      error: data => this.errorMessage = data.message[0],
      complete: () => console.log('completed')
    });
  }

  reload(load: boolean) {
      this.bankService.retrieveCustomerBanks().subscribe({
        next: d => this.banks = d
      })
  }
}
