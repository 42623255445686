import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { AuthService } from "src/app/core/service/auth.service";
import { Login } from "src/app/core/models/login";
import { ApiClientService } from "src/app/core/http-client/api-client.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {

  @ViewChild('closeModel') closeModel: ElementRef;
  loginRequest: Login = {};
  error: string;
  constructor(
    private authService: AuthService,
    private apiClient: ApiClientService,
    private router: Router,
    private toasteService: ToastrService
  ) {}

  ngOnInit() {}

  doLogin(): void {
    this.authService.login(this.loginRequest).subscribe({
      next: (res) => {
        this.authService.addLoggedInCustomerToStorage(res);
        this.toasteService.success('Welcome back ' + res.user.first_name || ' Guest');
        this.closeModel.nativeElement.click();
        if (this.router.url.includes('access-denined')) {
          this.router.navigate(['/']);
        }
      },
      error: (err) =>
        (this.error = this.apiClient.__handleValidationErrors(err)),
    });
  }

 

}
