import { Component, OnInit, ViewChild } from '@angular/core';
import { ChangePassword } from 'src/app/core/models/change-password.model';
import { AuthService } from 'src/app/core/service/auth.service';
import { Form, FormGroup, NgForm } from '@angular/forms';
import { error } from 'protractor';
import { ToastrService } from 'ngx-toastr';
import { ApiClientService } from 'src/app/core/http-client/api-client.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePassword : ChangePassword = {};
  @ViewChild('changePasswordForm', { static: true }) changePasswordForm: NgForm;
  err: string;

  constructor(private authService : AuthService, private toasterService: ToastrService, private apiClient:ApiClientService) { }

  ngOnInit(): void {
  }

  doChangePassword() : void {
    this.authService.changePassword(this.changePassword).subscribe({
      next: data => {
        this.changePasswordForm.resetForm();
        this.toasterService.success(data.detail);
      },
      error: err => this.err = this.apiClient.__handleValidationErrors(err)
    }
      
    );
  }
}
