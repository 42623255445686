<div class="store-sidebar-widget">
  <div class="widget-heading d-flex align-items-center mb-4">
    <i class="fal fa-info-circle me-2"></i>
    <h5 class="widget-title mb-0" i18n>About {{store.name_en}} </h5>
  </div>
  <p [innerHTML]="shortDesc">
  </p>
  <button (click)="seeMore()" id="myBtn" class="p-0" i18n *ngIf="readMore"> Read More <i class="fas fa-plus ms-2" aria-hidden="true"></i>
  </button>
  <button (click)="seeLess()" id="myBtn" class="p-0" i18n *ngIf="!readMore"> Read Less <i class="fas fa-minus ms-2"
      aria-hidden="true"></i> </button>

</div>
