import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']})
export class AppComponent implements OnInit{
  
  title = 'cashiback';
  show = true;
  public locationSubject: BehaviorSubject<any>;
  public locationObs: Observable<any>; 

  constructor( private translate: TranslateService) { 
      this.show = ! window.location.pathname.includes('redirect');
      translate.addLangs(['en', 'ar']);
      translate.setDefaultLang('en');
      translate.use('en')
   }

   ngOnInit(): void {} 


 

}
