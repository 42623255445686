<div class="second-header bg-img">
  <div class="container" *ngIf="store">
    <div class="shop-bg p-5">
      <div class="row">
        <div class="col-lg-8">
          <div class="store-box d-flex align-items-center justify-content-center justify-content-lg-start">
            <img [src]="cdn + store.logo" [alt]="store.name_en | titlecase" srcset="">
            <div class="store-details-box ms-3">
              <h2>{{store.name_en | titlecase}}</h2>
              <p i18n>Up to {{store.top_rate_v}} CashBack</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 d-flex align-items-center justify-content-center justify-content-lg-end mt-4 mt-lg-0">
          <span class="star-icon-wrapper me-4" *ngIf="isAuthorized">
            <i aria-hidden="true" [ngClass]="store.is_fav ? 'fas fa-star' : 'far fa-star'"
              (click)="addOrRemoveFav()"></i>
          </span>
         

          <a class="main-btn" data-toggle="modal" data-target="#sign-in" *ngIf="!isAuthorized">
            <div class="header-buttons">
                <small >{{'sign-in' | translate}}</small>
            </div>
          </a>
          <button routerLinkActive="true" class="main-btn" (click)="redirect()" target="_blank"
            *ngIf="isAuthorized">Shop Now
            <i class="far fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>