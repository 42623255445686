import { Component, OnInit } from '@angular/core';
import { Categories } from 'src/app/core/models/categories.model';
import { CategoriesService } from 'src/app/core/service/categories.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { LoginResponse } from 'src/app/core/models/login-response.model';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/service/common.service';
import { Rules } from 'src/app/core/models/rules.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  categories : Categories[] = [];
  loggedInUser : LoginResponse;
  cashbackRule : Rules;
  constructor(private _categoriesService: CategoriesService, private authService: AuthService, private router:Router, private _commonService:CommonService) { }

  ngOnInit() {
    this.retrieveCategories();
    this.retrieveCashbackRule();
    this.authService.currentUser.subscribe(x => this.loggedInUser = x);
  }

  private retrieveCategories () : void {
    this._categoriesService.retrieveCategories().subscribe(
      {
        next: data => this.categories = data
      }
    )
  }

  private retrieveCashbackRule(): void {
    this._commonService.retrieveRules().subscribe({
      next: data => this.cashbackRule = data.find(x => x.type === 'Referral')
    })
  }
  openLoginDialog() {
   
  }

  goToHowToEarnCB(): void {
    this.router.navigate['/how-to-earn'];
  }

  classApplied = false;
  secondSubMenuApplied = false;

  toggleClass() {
    this.classApplied = !this.classApplied;
  }
  toggleClass2() {
    this.secondSubMenuApplied = !this.secondSubMenuApplied;
  }

}
