import { Component, OnInit } from '@angular/core';
import { Categories } from 'src/app/core/models/categories.model';
import { Store } from 'src/app/core/models/store.model';
import { StoreService } from 'src/app/core/service/store.service';

@Component({
  selector: 'app-all-stores',
  templateUrl: './all-stores.component.html',
  styleUrls: ['./all-stores.component.css']
})
export class AllStoresComponent implements OnInit {

  stores: Store[] = [];
  previous : string;
  next: string;
  count: number
  category : Categories;

  constructor(private storeService: StoreService) { }

  ngOnInit(): void {
  }

  retrieveStores(category: Categories): void {
    this.category = category;
    this._retrieveStoreByCategoryId(category.slug);
  }

  private _retrieveStoreByCategoryId (category) : void {
    this.storeService.retrieveStoreByCategory(category).subscribe((data) => {
      this.stores = data.results;
      this.next = data.next;
      this.previous = data.previous;
      this.count = data.count;
    })
  }

  onScroll() : void {
    if(this.next === null || this.next === undefined) return;    
    this.storeService.retrieveStoreByCategoryAndPaginationLink(this.next).subscribe((data) => {
      this.stores.concat(data.results);
      this.next = data.next;
      this.previous = data.previous;
      this.count = data.count;
    })
  }

}
