import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '../models/store.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {  Paginator } from '../models/pagination.model';
import { ApiClientService } from '../http-client/api-client.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
 
  constructor(private apiClient: ApiClientService) { }
  
  retrieveStoreByCategory(categorySlug : string) : Observable<Paginator<Store>>{
    return this.apiClient.getRequest<Paginator<Store>>(`categories/${categorySlug}/stores`);
  }

  retrieveStoreByCategoryAndPaginationLink(link: string) : Observable<Paginator<Store>>{
    return this.apiClient.getRequest<Paginator<Store>>(`${link}`, null, null, true);
  }

  searchByStore(query: string): Observable<Paginator<Store>>{
    const httpParams = new HttpParams ()
      .set('q', query);
    return this.apiClient.getRequest<Paginator<Store>>(`store/search`, httpParams, true);
  }

  retrireveStoreDetails(storeId: number) : Observable<Store>{
    return this.apiClient.getRequest<Store>(`store/${storeId}`);
  }

  retrireveFavStore() : Observable<Paginator<Store>>{
    return this.apiClient.getRequest<Paginator<Store>>(`store/fav`);
  }

  addFavStore(storeId: number) : Observable<any>{
    return this.apiClient.postRequest<Paginator<Store>>(`store/fav/${storeId}/`, null);
  }

  removeFavStore(storeId: number) : Observable<any>{
    return this.apiClient.deleteRequest<Paginator<Store>>(`store/fav/${storeId}/`);
  }

  retrireveTopStore() : Observable<Paginator<Store>>{
    return this.apiClient.getRequest<Paginator<Store>>(`store/top`);
  }

  retreiveStoreWithRedirectLink(storeId: number) : Observable<Store>{
    return this.apiClient.getRequest<Store>(`store/${storeId}/redirect`);
  }



}
