
<section class="parent-slider">

  <!-- sliders -->
  <ngx-slick-carousel class="main-slider" #slickModal="slick-carousel" [config]="slideConfig" responsive="breakpoints">

    <div ngxSlickItem class="slider-item" *ngFor="let slider of sliders"
      style="background-image: url({{cdn}}{{slider.file}});">

      <ng-container *ngIf="slider.type === 'Video'">

        <div class="fourth-slider">
          <a class="slider-play-icon slider-popup" href="#" data-toggle="modal" data-target="#sliderPopupVideo"
            (click)="url = slider.youtube_link">
            <i class="icon-play"></i>
          </a>
        </div>


      </ng-container>


      <ng-container  *ngIf="slider.type === 'Image'">
          <!-- <img [src]='cdn + slider.file'> -->
          <div class="cashiback-slider-content">
              <span>{{slider.title}}</span>
              <!-- <h2 class="slider-title">{{slider.title}}</h2> -->
              <h2 class="slider-title" style="color:#fff;">Get up to 40% Cash Back<br> at over 2,500 stores</h2>
              <a  class="main-btn" data-toggle="modal" data-target="#newRegisterModal" *ngIf="!loggedInUser">{{'join-now' | translate}}</a>

        </div>
      </ng-container>



    </div>
  </ngx-slick-carousel>
</section>


<!-- Slider Popup Video Modal -->
<div class="modal fade" id="sliderPopupVideo" tabindex="-1" aria-labelledby="sliderPopupVideoLabel" aria-hidden="true"
  *ngIf="url">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content border-0">
      <iframe [src]="safeURL()" height="300px" allowfullscreen>
      </iframe>
    </div>
  </div>
</div>
