import { BrowserModule } from "@angular/platform-browser";
import { DEFAULT_CURRENCY_CODE, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModuleModule } from "./module/shared-module/shared-module.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthModule } from "./module/auth/auth.module";
import { StoresModule } from "./module/stores/stores.module";
import { ProfilesModule } from "./module/profiles/profiles.module";
import { EarnAndReferModule } from "./module/earn-and-refer/earn-and-refer.module";
import { HomeModule } from "./module/home/home.module";
import { NotifierModule } from "angular-notifier";
import { StaticModule } from "./module/static/static.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { HttpInterceptorService } from "./core/http-client/http-interceptor.service";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { NumbersOnlyInputDirectiveDirective } from "./core/directive/numbers-only-input-directive.directive";
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import { TranslateNamePipe } from "./module/shared-module/pipe/translate-name.pipe";


@NgModule({
    declarations: [AppComponent, NumbersOnlyInputDirectiveDirective],
    imports: [
        FormsModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        AuthModule,
        StoresModule,
        ProfilesModule,
        EarnAndReferModule,
        SharedModuleModule,
        HomeModule,
        StaticModule,
        NotifierModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            positionClass: "toast-bottom-full-width",
            enableHtml: true,
            timeOut: 5000,
        }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        TableModule,
        DialogModule
        
    ],
    providers: [
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'SAR ' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
        Location,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        CookieService,
        ToastrService
    ],
    bootstrap: [AppComponent],
    exports:[
       // SharedModuleModule
    ]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
