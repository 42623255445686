import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ProfileService } from 'src/app/core/service/profile.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { LoginResponse } from 'src/app/core/models/login-response.model';
import { Router } from '@angular/router';
import { Cashback } from 'src/app/core/models/cashback.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cashback-activity',
  templateUrl: './cashback-activity.component.html',
  styleUrls: ['./cashback-activity.component.css']
})
export class CashbackActivityComponent implements OnInit {

  user: LoginResponse;
  cashback: Cashback [] = [];
  cdn = environment.cdn;


  constructor(private profileService: ProfileService, private authService:AuthService, private router:Router) { }

  ngOnInit(): void {
    this.authService.currentUser.subscribe(data => this.user = data);
    this.loadCashback();
  }

  private loadCashback(): void {
    this.profileService.retrieveCashbackActivity()
    .subscribe(d => this.cashback = d)
  }

}


