import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from 'src/app/core/service/store.service';
import { Store } from 'src/app/core/models/store.model';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.css']
})
export class StoreDetailsComponent implements OnInit {
  storeId : number;
  store : Store;
  constructor(private route: ActivatedRoute, private storeService: StoreService, private title:Title) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.storeId = Number(paramMap.get('id'));
      this._retrieveStoreDetails();
    }); 
  }

  private _retrieveStoreDetails() : void {
    this.storeService.retrireveStoreDetails(this.storeId).subscribe({
      next: data => {
        this.store = data;
        this.title.setTitle(this.store.name_en);
      }
    })
  }
 
}
