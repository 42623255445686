import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';
import { StoreService } from 'src/app/core/service/store.service';

@Component({
  selector: 'app-fav-store',
  templateUrl: './fav-store.component.html',
  styleUrls: ['./fav-store.component.css']
})
export class FavStoreComponent implements OnInit {
  stores : Store [] = [];
  previous : string;
  next: string;
  count: number

  constructor(private storeService: StoreService) { }

  ngOnInit() {
    this._retrieveFavStores();
  }

  private _retrieveFavStores () : void {
    this.storeService.retrireveFavStore().subscribe((data) => {
      this.stores = data.results;
      this.next = data.next;
      this.previous = data.previous;
      this.count = data.count;
    })
  }


  onScroll() : void {
    if(this.next === null || this.next === undefined) return;
    this.storeService.retrieveStoreByCategoryAndPaginationLink(this.next).subscribe((data) => {
      this.stores.concat(data.results);
      this.next = data.next;
      this.previous = data.previous;
      this.count = data.count;
    })
  }
}
