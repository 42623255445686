import { Injectable, Inject, Injector } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { ToastrService } from 'ngx-toastr';
import { ErrorDetails } from '../models/error.model';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {
  api = environment.baseAPI;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
        }),
        withCredentials: true
  };
  errorMsg: string;

  constructor(private httpClient: HttpClient) { 
  }


  private _buildHttpOptions(params?: HttpParams, withCredentials?: boolean) {
      // this.httpOptions['withCredentials'] = withCredentials;
      if (params) {
        this.httpOptions['params'] = params;
      }
  }

  getRequest<T>(url: string, params?: HttpParams, withCredentials?: boolean, isPaginationLink?:boolean) : Observable<T> {
    this._buildHttpOptions(params, withCredentials);
    return this.httpClient.get<T>(isPaginationLink ? url : this.api + url, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteRequest<T>(url: string, params?: HttpParams, withCredentials?: boolean, isPaginationLink?:boolean) : Observable<T> {
    this._buildHttpOptions(params, withCredentials);
    return this.httpClient.delete<T>(isPaginationLink ? url : this.api + url, this.httpOptions).pipe(catchError(this.handleError));
  }

  postRequest<T>(url: string, body: any,  params?: HttpParams, withCredentials?: boolean) : Observable<T> {
    this._buildHttpOptions(params, withCredentials);
    return this.httpClient.post<T>(this.api + url, body, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  private handleError (err: HttpErrorResponse) : Observable<any>{
      return throwError(err.error);
  }

  __handleValidationErrors (err :  any) : string {
    let message = err.message[0];
    return message;
  }

}
