import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Categories } from 'src/app/core/models/categories.model';
import { CategoriesService } from 'src/app/core/service/categories.service';

@Component({
  selector: 'app-all-stores-category',
  templateUrl: './all-stores-category.component.html',
  styleUrls: ['./all-stores-category.component.css']
})
export class AllStoresCategoryComponent implements OnInit {

  @Output() categoryEmitter: EventEmitter<Categories> = new EventEmitter();

  categories : Categories[] = [];
  constructor(private _categoriesService: CategoriesService) { }

  ngOnInit(): void {
    this.retrieveCategories();
  }

  private retrieveCategories () : void {
    this._categoriesService.retrieveCategories().subscribe(
      {
        next: data => { 
          this.categories = data;     
          this.categoryEmitter.emit(this.categories[0]);
        }
      }
    )
  }

  changeCategory(category: Categories): void{
    this.categoryEmitter.emit(category);
  }
}
