import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Categories } from 'src/app/core/models/categories.model';
import { Store } from 'src/app/core/models/store.model';
import { CategoriesService } from 'src/app/core/service/categories.service';
import { CommonService } from 'src/app/core/service/common.service';
import { SocialService } from 'src/app/core/service/social.service';
import { StoreService } from 'src/app/core/service/store.service';

@Component({
  selector: 'app-footer-component',
  templateUrl: './footer-component.component.html',
  styleUrls: ['./footer-component.component.css']
})
export class FooterComponentComponent implements OnInit {
  categories : Categories [] = [];
  stores : Store [] = [];
  constructor(private socialNetworkService: SocialService, private commonService: CommonService, private storeService: StoreService, private categoryService: CategoriesService) { }

  ngOnInit() {
    this._stores();
    this._categories();
  }

  networks$ = this.socialNetworkService.list();
  contactInfo$ = this.commonService.contactInfo();

  private _categories(): void {
    this.categoryService.retrieveCategories()
      .subscribe(x => this.categories = x.splice(0,8));
    
  }

  private _stores(): void {
    this.storeService.retrireveTopStore().subscribe(x => this.stores = x.results.splice(0,5)) ;
  }
}
