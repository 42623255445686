<section class="mobilestore-wrapper section-gap">
  <div class="container">
    <div class="mobilestore p-5 p-lg-0">
      <div class="row">
        <div class="col-lg-4 d-none d-lg-block">
          <div class="mobilestore-banner">
            <img src="../../../../assets/images/home/mob.png">
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 d-flex align-items-center justify-content-center mb-3 mb-md-0">
          <div class="text-mobileStore">
            <h2 i18n>Cashiback on store</h2>
            <p class=" " i18n>Discover even more deals with Cashiback</p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 d-flex align-items-center justify-content-center">
          <div class=" btns-mobileStore ">
            <a href="#" class="single-btn-mobileStore w-100"> <img src="../../../assets/images/home/apple.png"
                alt="apple"></a>
            <a href="#" class="single-btn-mobileStore w-100"> <img src="../../../assets/images/home/android.png"
                alt="android"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>