<div class="store-cat section-gap">
  <div class="container">
    <div class="section-heading mb-5">
      <h1 class="main-title" i18n>Top Cashback Stores</h1>
      <p class="secondary-text" i18n>Check out today’s Top Stores offering
        extra Cash Back &amp; special discounts. Extra Savings from your
        favorite stores. Shop today to take advantage of special offers and hot deals
        to help you save the most with Cash Back at CashiBack</p>
    </div>
    <div class="products">
      <div class="row mt-2 row-cols-lg-5 row-cols-md-3 row-cols-sm-2" infiniteScroll [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
        <app-top-store-card [store]="store" *ngFor="let store of stores" class="col mb-3"></app-top-store-card>
      </div>
    </div>
  </div>
</div>
<app-mobile-app-component></app-mobile-app-component>