<section class="join mt-5">
    <div class="container">
        <div class="join-content py-5 text-center">
            <h2 i18n>
                Discover even more deals with Cashiback
            </h2>
            <p class="mb-4" i18n>Start earning Cash Back at over 2,500 of the biggest stores and specialty
                boutiques.
            </p>
            <button type="button" class="second-btn" i18n (click)="openRegistrationDialog()">Join Now</button>
        </div>
    </div>
</section>