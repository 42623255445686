import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { Categories } from '../models/categories.model';
import { ApiClientService } from '../http-client/api-client.service';



@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private apiClient: ApiClientService) { }

  retrieveCategories(): Observable<Categories[]> {
    return this.apiClient.getRequest<Categories[]>(`categories`);
  }

  retrievePopularCategories(): Observable<Categories[]> {
    return this.apiClient.getRequest<Categories[]>(`categories/popular`);
  }


}
