import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ProfileService } from 'src/app/core/service/profile.service';
import { Clicks } from 'src/app/core/models/clicks.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-click-activity',
  templateUrl: './click-activity.component.html',
  styleUrls: ['./click-activity.component.css']
})
export class ClickActivityComponent implements OnInit {
  clicks : Clicks [] = [];
  cdn = environment.cdn;


  constructor(private profileService: ProfileService) {
   
  }

  ngOnInit(): void {  
    this.loadClicks();
  }

  private loadClicks(): void {
    this.profileService.retrieveClickActivity()
    .subscribe(d => this.clicks = d);
  }



}
