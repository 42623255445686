<div class="container clearfix">

<div class="row gutter-40 col-mb-80">
    <!-- Post Content
    ============================================= -->
    <div class="col-lg-12">


            <div id="not-found">
                
                <section class="not-found">
                    <img src="../../../../assets/images/system/unplug.png">
                    <h2 i18n>Whoops! Page Doesn't Exist!</h2>
                    <p i18n>The page you are looking doesn't exist for can't be found.</p>
                    <button class="btn  btn-danger" (click)="goHome()" i18n>Back to Home Page </button>
                </section>

            </div>
          



    </div><!-- .postcontent end -->



</div>
</div>