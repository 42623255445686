import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { LoginResponse } from 'src/app/core/models/login-response.model';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.css']
})
export class ProfilesComponent implements OnInit {

  loggedInUser : LoginResponse;
  currentURL: string;
  constructor(private authService: AuthService, private router: Router) {
      this.router.events.subscribe((event: NavigationEnd) => {
          if(event instanceof NavigationEnd ){
            this.currentURL = event.url;
        }
      });

      this.authService.currentUser.subscribe(x => this.loggedInUser=x);

   }

  ngOnInit(): void {
    this.currentURL = window.location.href;
  }

  isActiveLink(target): boolean {
    return this.currentURL.search(target) > 1;
  }

}
