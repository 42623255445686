import { Component, OnInit, Input } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';

@Component({
  selector: 'app-about-store',
  templateUrl: './about-store.component.html',
  styleUrls: ['./about-store.component.css']
})
export class AboutStoreComponent implements OnInit {

  @Input() store: Store;
  readMore = true;
  shortDesc: string;
  constructor() { }

  ngOnInit() {
    this.shortDesc = this.store.desc_en.substr(0,50) + "....";
  }

  seeMore(): void {
    console.log('more');
    
    this.readMore = false;
    this.shortDesc = this.store.desc_en;
  }

  seeLess(): void {
    console.log('less');
    
    this.readMore = true;
    this.shortDesc = this.store.desc_en.substr(0,50) + "....";

  }

}
