import { Component, OnInit } from '@angular/core';
import { LoginResponse } from 'src/app/core/models/login-response.model';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  loggedInUser : LoginResponse;

  constructor(private authService: AuthService) { 
    this.authService.currentUser.subscribe(x => this.loggedInUser=x);
  }

  ngOnInit() {
  }

}
