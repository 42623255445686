import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyComponent } from './privacy/privacy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TacComponent } from './tac/tac.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HelpComponent } from './help/help.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { ServerErrorPageComponent } from './server-error-page/server-error-page.component';
import { HowToEarnCashbackComponent } from './how-to-earn-cashback/how-to-earn-cashback.component';
import { UnAuthorizedComponent } from './un-authorized/un-authorized.component';
import { LoggedInCustomerGuard } from 'src/app/core/logged-in-customer.guard';

const routes: Routes = [
  { path: 'privacy', component: PrivacyComponent},
  { path: 'about-us', component: AboutUsComponent},
  { path: 'terms-and-condition', component: TacComponent},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'help', component: HelpComponent},
  { path: 'error', component: ServerErrorPageComponent},
  { path: 'how-to-earn', component: HowToEarnCashbackComponent},
  { path: 'access-denined', component: UnAuthorizedComponent},
  { path: '**', component: NotFoundPageComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticRoutingModule { }
