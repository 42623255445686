export interface User {
    username?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    date_joined?: Date;
    customer?: {
        mobile_number?: string;
        date_of_birth?: Date;
        gender?: string;
        is_verified?: boolean;
        available_balance?: number;
        pending_balance?: number;
        withdraw_balance?: number;
        currency?: {
            code?: string;
        };
        country?: number;
    };
}

export interface LoginResponse {
        token: string; 
        user: User
}
