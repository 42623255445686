import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Categories } from 'src/app/core/models/categories.model';
import { Store } from 'src/app/core/models/store.model';
import { StoreService } from 'src/app/core/service/store.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit, OnChanges {

  @Input() category: Categories;
  stores : Store [] = [];

  constructor(private storeService: StoreService) { }

  ngOnInit(): void {
    this._populateCategoryStore();
  }

  private _populateCategoryStore(): void {
    console.log("retrieve stores");      
    this.storeService.retrieveStoreByCategory(this.category.slug).subscribe({
      next: data => this.stores = data.results.slice(0, 8)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this._populateCategoryStore();
    
}

}
