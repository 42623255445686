import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoreDetailsComponent } from './store-details/store-details.component';
import { TopStoresComponent } from './top-stores/top-stores.component';
import { FavStoresComponent } from './fav-stores/fav-stores.component';
import { StoresByCategoriesComponent } from './stores-by-categories/stores-by-categories.component';
import { StoreRedirectComponent } from './store-redirect/store-redirect.component';
import { AllStoresComponent } from './all-stores/all-stores.component';
import { LoggedInCustomerGuard } from 'src/app/core/logged-in-customer.guard';

const routes: Routes = [
  {path: ':id/details', component: StoreDetailsComponent, runGuardsAndResolvers: 'always'},
  {path: ':id/redirect', component: StoreRedirectComponent, canActivate: [LoggedInCustomerGuard]},
  {path: 'top', component: TopStoresComponent},
  {path: 'fav', component: FavStoresComponent, canActivate: [LoggedInCustomerGuard]},
  {path: 'all', component: AllStoresComponent},
  {path: ':slug/categories', component: StoresByCategoriesComponent, runGuardsAndResolvers: 'always'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StoresRoutingModule { }
