import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomerBank } from 'src/app/core/models/customer.bank.model';
import { BanksService } from 'src/app/core/service/banks.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bank-card',
  templateUrl: './bank-card.component.html',
  styleUrls: ['./bank-card.component.css']
})
export class BankCardComponent implements OnInit {
  @Input() bank! : CustomerBank ;
  @Output('selectedBank') emitBank = new EventEmitter();
  @ViewChild('bank') bankElement: ElementRef;
  cdn = environment.cdn;
  constructor(private bankService: BanksService, private toastSerice: ToastrService) { }

  ngOnInit(): void {
    console.table(this.bank);
  }


  get maskAccountNumber() {
    const accountNumber = this.bank.account_number;
    return accountNumber;
   }

   get logo() {
    return this.cdn + this.bank.bank.logo;
   }

   get isElectron() {
    return this.bank.bank.is_electronic_bank;
   }

   get accountName () {
    return this.bank.account_name;
   }

   get accountNumber() {
    return this.bank.account_number;
   }
   openVerificationDialog() {
  }

  deleteBank(id: number) {
    this.bankService.deleteCustomerBank(this.bank.pk).subscribe({
      next: d => {
        this.toastSerice.success('Bank deleted successfully');
        this.bankElement.nativeElement.hidden = true;
      }
    })
  }

  pushBank(bankId) {
    console.log(bankId);
    this.emitBank.emit(bankId);
  }
}
