<div class="store-sidebar-widget">
  <div class="widget-heading d-flex align-items-center mb-4">
    <i class="fal fa-ballot me-2"></i>
    <h5 class="widget-title mb-0" i18n>When will I get my cashback</h5>
  </div>
  <!-- start cashback-timeline -->
  <div class="cashback-timeline">
    <div class="cashback-timeline-step">
      <p class="btn-title " i18n>Purshace</p>
      <div class="btn-cashback line-after " i18n>Today</div>
    </div>
    <div class="cashback-timeline-step">
      <p class="btn-title mt-5 " i18n>Tracked In</p>
      <div class="btn-cashback line-after " i18n>{{store.tracking_speed_days}} Days</div>
    </div>
    <div class="cashback-timeline-step">
      <p class="btn-title mt-5" i18n>Claim In</p>
      <div class="btn-cashback" i18n>{{store.claim_days}} Days</div>
    </div>
  </div>
</div>
