import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpResponse, HttpErrorResponse, HttpRequest, HttpEvent } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { ErrorDetails } from '../models/error.model';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { CommonService } from '../service/common.service';


@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private toasterService: ToastrService, private authService: AuthService, private router:Router, private commonService:CommonService) { }

  intercept(req: import("@angular/common/http").HttpRequest<any>, next: import("@angular/common/http").HttpHandler): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {
    req = this.__injectJwtToken(req);
    return next.handle(req).pipe(
      catchError((err: any) => {
          if(err instanceof HttpErrorResponse) {
              this.handleError(err);
             
              //log error 
          }

          return throwError(err);
      }));

  }


  private __injectJwtToken (req : HttpRequest<any>) : HttpRequest<any>{
      if (this.authService.curretUser && this.authService.curretUser.token) {
        req = req.clone({
            setHeaders: { 
                Authorization: `Bearer ${this.authService.curretUser.token}`
            }
        });
      }
      return req;
  }

    // Handling HTTP Errors using Toaster
    private handleError(err: HttpErrorResponse) {
      let errorMessage: string;
      if (err.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        errorMessage = `An error occurred: ${err.error.message}`;
      } else {
       // The backend returned an unsuccessful response code.
      switch (err.status) {
        case 400:
          errorMessage = this._convertArrayToString(err.error.message);        
          break;
        case 401:
          errorMessage = "You need to log in to do this action.";
          this.authService.refreshTokenOrLogout();
          break;
        case 403:
          errorMessage = "You don't have permission to access the requested resource.";
          break;
        case 404:
          errorMessage = "The requested resource does not exist.";
          break;
        case 412:
          errorMessage = "Precondition Failed.";
          break;
        case 500:
          errorMessage = "Internal Server Error.";
          break;
        case 503:
          errorMessage = "The requested service is not available.";
          break;
        case 504:
            errorMessage = "Server unvailable.";
            break;
        case 422:
          errorMessage = "Validation Error!";
          break;
        default:
          errorMessage = "Something went wrong!";
      }
    }

    if (errorMessage) {
      this.toasterService.error(errorMessage);
    }
  }


  private _convertArrayToString(messages: string[]): string {
    let message : string = '<ul>';
    messages.forEach(b => message += '<li>' + b + '</li>');
    message += '</ul>'
    return message;
  }
}
