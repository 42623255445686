<app-store-detail-header [store]="store"></app-store-detail-header>
<section class="main-content section-gap" *ngIf="store">
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-5 col-lg-4 mt-5 order-last order-md-first">
        <div class="sidebar-wrapper pe-md-4">
          <app-about-store [store]="store" *ngIf="store"></app-about-store>
          <app-cashback-cycle [store]="store" *ngIf="store"></app-cashback-cycle>
          <app-store-terms [store]="store" *ngIf="store"></app-store-terms>
          <app-trending-stores></app-trending-stores>
        </div>
      </div>
      <div class="col-md-7 col-lg-8 mt-5 order-first order-md-last">
        <div class="main-categories-wrapper ps-md-4">
          <app-store-categories [store]="store" *ngIf="store"></app-store-categories>
          <!-- <app-offers [store]="store" *ngIf="store"></app-offers> -->
        </div>
      </div>
    </div>
  </div>
</section>