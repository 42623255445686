import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';
import { StoreService } from 'src/app/core/service/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-store-card',
  templateUrl: './store-card.component.html',
  styleUrls: ['./store-card.component.css']
})
export class StoreCardComponent implements OnInit {

  @Input() store: Store;
  @Input() isFav = false;
  cdn = environment.cdn;

  @ViewChild('store') private draggableElement: ElementRef;


  constructor(private storeService: StoreService) { }

  ngOnInit() {
    
  }

  addOrRemoveFav() : void {
    if (this.isFav) {
      this.storeService.removeFavStore(this.store.pk).subscribe(data => {
        this.isFav = false;
        this.store.is_fav = false;
        // var element = document.getElementById("store-"+this.store.pk);
        // element.parentNode.removeChild(element);
      });

    } else {
      this.storeService.addFavStore(this.store.pk).subscribe({
        next: d => this.store.is_fav = true
      });      
    }    
  }

}
