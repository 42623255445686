<section class="earn-banner section-gap">
  <div class="container">
    <div class="earn-content p-5">
      <!-- <img src="../../../../assets/images/how-to-earn/how-to-earn-banner.jpg" /> -->
      <h1>How To Earn Cashback</h1>
      <p>Check out the stores that offers 35% more Cash Back to help you save more, and to save even you will
        find
        stores that gives double the Cash Back for a limited time through Daily Double.</p>
    </div>
  </div>
</section>


<section class="refer-steps section-gap">
  <div class="container mt-3">
    <div class="row">

      <div class="col-lg-3 col-md-4  col-sm-12 sign-up icons mb-5 mb-md-0">
        <div class="main-icon text-center">
          <i class="fal fa-shopping-bag"></i>
        </div>
        <h4 class="text-center">Shop on any store through cashiback </h4>
      </div>

      <div class="col arrow-icon-line22">
        <i class="fas fa-chevron-right"></i>
      </div>

      <div class="col-lg-3 col-md-4  col-sm-12 sign-up icons mb-5 mb-md-0">
        <div class="main-icon text-center">
          <i class="fal fa-ticket-alt"></i>
        </div>
        <h4 class="text-center">Get cashback in your account</h4>
      </div>

      <div class="col arrow-icon-line22">
        <i class="fas fa-chevron-right"></i>
      </div>

      <div class="col-lg-3 col-md-4  col-sm-12 earn-dollars icons mb-5 mb-md-0">
        <div class="main-icon text-center">
          <i class="fal fa-wallet"></i>
        </div>
        <h4 class="text-center">Payout to your bank account</h4>
      </div>

    </div>
  </div>
</section>

<section class="section-gap info-box">
  <div class="container">
    <div class="row mt-5">
      <div class="col-10 m-auto col-lg-6 mb-3 mb-lg-0">
        <div class="how-to-earn-txt-block shadow rounded p-4 h-100">
          <h3>Save Everywhere</h3>
          <p>Save up to 30% on discounted gift cards from 4,000+ brands on our marketplace. Combine them with
            store
            sales and coupons to save even more.</p>
        </div>
      </div>

      <div class="col-10 m-auto col-lg-6">
        <div class="how-to-earn-txt-block shadow rounded p-4 h-100">
          <h3>Pay In Store or Online</h3>
          <p>Most cards are delivered electronically within minutes. Log into your Raise account online or
            download the
            Raise app to access and redeem your cards.</p>
        </div>
      </div>
    </div>
  </div>

</section>

<app-mobile-app-component></app-mobile-app-component>