import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CashbackOverviewComponent } from './cashback-overview/cashback-overview.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { FavStoreComponent } from './fav-store/fav-store.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { WithdrawalComponent } from './withdrawal/withdrawal.component';
import { ProfilesComponent } from './profiles.component';

const routes: Routes = [
  {path : '' , component: ProfilesComponent , children : [
    {path: 'cashback-overview', component: CashbackOverviewComponent},
    {path: 'account-info', component: AccountInfoComponent},
    {path: 'fav-store', component: FavStoreComponent},
    {path: 'change-password', component: ChangePasswordComponent},  
    {path: 'withdrawal', component: WithdrawalComponent}
  
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfilesRoutingModule { }
