import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClientService } from '../http-client/api-client.service';
import { Bank } from '../models/bank.model';
import { CustomerBank } from '../models/customer.bank.model';

@Injectable({
  providedIn: 'root'
})
export class BanksService {

  constructor(private apiClient:ApiClientService) { }

  retrieveCustomerBanks(): Observable<CustomerBank[]> {
    return this.apiClient.getRequest<CustomerBank[]>(`profile/banks`, null, true, false);
  }

  addCustomerBank(customerBank: CustomerBank): Observable<CustomerBank> {
    return this.apiClient.postRequest<CustomerBank>(`profile/banks`, customerBank, null, true);
  }

  deleteCustomerBank(id: number): Observable<void> {
    return this.apiClient.deleteRequest<void>(`profile/${id}/banks`);

  }

  retrieveBanks(): Observable<Bank[]> {
    return this.apiClient.getRequest<Bank[]>(`banks`, null, true, false);
  }

}
