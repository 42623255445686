<!-- Model sign up
						============================================= -->
<!--Register Modal -->
<div class="modal fade" id="newRegisterModal" tabindex="-1" aria-labelledby="newRegisterModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-body p-0">
        <form #singupForm="ngForm" (ngSubmit)="singupForm.form.valid && signup()" *ngIf="registration.country_code"
          class="needs-validation" novalidate>
          <div class="modal-register sign-up-form" [hidden]="hideReg">
            <div class="card">
              <!-- Modal Header-->
              <div mat-dialog-title class="card-header py-3 px-5">
                <button type="button" class="btn close-form btn-close" aria-label="Close" data-dismiss="modal" #closeModel></button>
                <div class="main-icon mb-3">
                  <i class="fal fa-clipboard-user"></i>
                </div>
                <h2 i18n>Sign Up Now</h2>
                <p i18n>Welcome to CashiBack</p>
              </div>
              <!-- Modal Body-->
              <div mat-dialog-content class="modal-card-body px-lg-5">

                <div class="row">
                  <!-- Modal left side -->
                  <div class="col-lg-6 pe-md-4">
                    <div class="box-model d-flex align-items-center">
                      <div class="main-icon"><i class="fal fa-sack-dollar"></i></div>
                      <h3 class="box-title ms-3" i18n>We’ve helped our members <br />
                        get over $626123 Cash Back</h3>
                    </div>

                    <p class="text-secondary">Sign Up for free and get {{cashbackRule?.value}} SAR Bonus</p>

                    <div class="join-steps">
                      <h4 i18n>Why You Should Join?</h4>
                      <ol class="steps list-unstyled">
                        <li i18n>Earn Cash Back at the stores you love</li>
                        <li i18n>Find the best coupons and deals</li>
                        <li i18n>Compare prices between different supplier</li>
                        <li i18n>Get Cash Back by Bank Transfer or PayPal</li>
                      </ol>
                    </div>
                  </div>
                  <!-- Modal left side sign up form -->
                  <div class="col-lg-6 ps-md-4">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <div class="error-message" *ngIf="errors?.length > 0">
                          <i class="far fa-check-circle" aria-hidden="true"></i>
                          <p *ngFor="let error of errors">{{error}} </p>
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <label i18n>Username</label>
                        <input type="text" i18n-placeholder placeholder="Enter your username"
                          [(ngModel)]="registration.username" #username="ngModel" name="username" required
                          [ngClass]="(singupForm.submitted && username.invalid) ? 'form-control invalid' : ''">

                        <div i18n *ngIf="singupForm.submitted && username.invalid && username.errors.required"
                          class="invalid-feedback">
                          Username is required
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <label i18n>E-mail</label>
                        <input type="email" i18n-placeholder placeholder="Enter your email" name="email"
                          [(ngModel)]="registration.email" #email="ngModel" required
                          [ngClass]="(singupForm.submitted && email.invalid) ? 'form-control invalid' : ''">
                        <div i18n *ngIf="singupForm.submitted && email.invalid && email.errors.required"
                          class="invalid-feedback">
                          Email is required
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <label i18n>Mobile Number </label>
                        <div class="input-group number-input">
                          <select class="form-select border-end-0" required name="country"
                            [(ngModel)]="registration.country_code" #country="ngModel"
                            [ngClass]="(singupForm.submitted && country.invalid) ? 'unselected' : ''"
                            style="width: 100px;">
                            <option *ngFor="let counrty of countries" [value]="counrty.pk">{{counrty.phone}}</option>
                          </select>
                          <input type="tel" class="form-control border-start-0" i18n-placeholder
                            placeholder="Enter mobile number" name="mobile" #mobile="ngModel"
                            [(ngModel)]="registration.mobile_number" required
                            [ngClass]="(singupForm.submitted && mobile.invalid) ? 'form-control invalid' : ''"
                            autocomplete="off" style="width: calc(100% - 100px);">
                          <div i18n *ngIf="singupForm.submitted && mobile.invalid && mobile.errors.required"
                            class="invalid-feedback">
                            Mobile number is required
                          </div>
                          <br>
                          <div i18n *ngIf="singupForm.submitted && country.invalid && country.errors.required"
                            class="invalid-feedback">
                            Mobile code is required
                          </div>

                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <label i18n>Password</label>
                        <input type="password" i18n-placeholder placeholder="Enter Password" name="password1"
                          [(ngModel)]="registration.password1" #password1="ngModel" required
                          [ngClass]="(singupForm.submitted && password1.invalid) ? 'form-control invalid' : ''">

                        <div i18n *ngIf="singupForm.submitted && password1.invalid && password1.errors.required"
                          class="invalid-feedback">
                          Password 1 is required
                        </div>
                      </div>
                      <div class="col-12 mb-3">
                        <label i18n>Confirm password</label>
                        <input type="password" i18n-placeholder placeholder="Confirm Password" name="password2"
                          [(ngModel)]="registration.password2" #password2="ngModel" required
                          [ngClass]="(singupForm.submitted && password2.invalid) ? 'form-control invalid' : ''">

                        <div i18n *ngIf="singupForm.submitted && password2.invalid && password2.errors.required"
                          class="invalid-feedback">
                          Password 2 is required
                        </div>
                      </div>
                      <div class="col-12">
                        <input id="checkbox-11" class="checkbox-style" name="checkbox-11" type="checkbox" checked="" [(ngModel)]="registration.terms" #terms="ngModel" required>
                        <label for="checkbox-11" class="checkbox-style-3-label terms">By continuing, you agree to
                          our <a style="color:#f05864" data-dismiss="modal" data-target="#termsConditionsModal" data-toggle="modal">Terms
                            & Privacy</a></label>
                        <mat-checkbox class="checkbox-style" id="checkbox-11">Checked</mat-checkbox>

                        <div i18n *ngIf="singupForm.submitted && terms.invalid && terms.errors.required" class="invalid-feedback">Terms and condition is required</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Modal footer for signup bottom-->
              <div mat-dialog-actions class="model-footer px-5">
                <button type="submit" class="main-btn" i18n>Sign Up</button> <br i18n />
                Already a member? <a data-dismiss="modal" data-toggle="modal" data-target="#sign-in" style="color: #f05864" i18n> Sign In
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Model Terms & Conditions
						============================================= -->
<div class="modal fade" id="termsConditionsModal" tabindex="-1" aria-labelledby="termsConditionsModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-register termsConditionsModal">
        <div class="card">
          <div class="back-icon">
            <a data-toggle="modal" data-target="#newRegisterModal" data-dismiss="modal"><i class="fal fa-arrow-left"></i></a>
          </div>
          <!-- Modal Header-->
          <div class="card-header py-3 px-5">
            <div class="main-icon">
              <i class="fa fa-file-alt"></i>
            </div>
            <h2 class="mt-3" i18n>Terms & Conditions</h2>
          </div>
          <!-- Modal Body-->
          <div class="modal-card-body px-5">
            <p [innerHTML]="terms | translateName"></p>
          </div>
          <!-- Modal footer for signup bottom-->
          <div class="model-footer px-5">
            <!-- <button type="button" data-dismiss="modal" class="main-btn" i18n>Agree</button> -->
            <a data-toggle="modal" data-target="#newRegisterModal" data-dismiss="modal"  class="main-btn" (click)="registration.terms = true">Agree</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
