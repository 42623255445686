import { Component, OnInit } from '@angular/core';
import { PromotionService } from 'src/app/core/service/promotion.service';
import { UserPromotion } from 'src/app/core/models/user-promotion.model';
import { StoreService } from 'src/app/core/service/store.service';
import { Store } from 'src/app/core/models/store.model';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.css']
})
export class DiscountsComponent implements OnInit {

  stores: Store [] = [];
  constructor(private _storeService: StoreService) { }

  ngOnInit() {
    this._retrieveStores();
  }

  private _retrieveStores(): void {
    this._storeService.retrireveTopStore().subscribe({
      next: d => this.stores = d.results
    })
  }
}
