import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-to-earn-cashback',
  templateUrl: './how-to-earn-cashback.component.html',
  styleUrls: ['./how-to-earn-cashback.component.css']
})
export class HowToEarnCashbackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
