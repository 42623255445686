import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-selling',
  templateUrl: './top-selling.component.html',
  styleUrls: ['./top-selling.component.css']
})
export class TopSellingComponent implements OnInit {

  constructor(private _rotuer:Router) { }

  ngOnInit(): void {
  }

  allStores() : void {
    this._rotuer.navigate(['stores/all']);
  }
}
