import { Component, Input, OnInit } from '@angular/core';
import { Categories } from 'src/app/core/models/categories.model';
import { Store } from 'src/app/core/models/store.model';

@Component({
  selector: 'app-all-store-details',
  templateUrl: './all-store-details.component.html',
  styleUrls: ['./all-store-details.component.css']
})
export class AllStoreDetailsComponent implements OnInit {

  @Input() stores: Store [] = [];
  @Input() category : Categories;
  constructor() { }

  ngOnInit(): void {
  }

}
