import { Injectable } from '@angular/core';
import { ApiClientService } from '../http-client/api-client.service';
import { Login } from '../models/login';
import { LoginResponse } from '../models/login-response.model';
import { RegistrationModel } from '../models/registration.model';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { ChangePassword } from '../models/change-password.model';
import { ResetPassword } from '../models/reset-password.model';
import { ResetPasswordcConfirm } from '../models/reset-password-confirm.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private LOGGEN_IN_CUSTOMER = 'LOGGED_IN';
  private CUSTOMER_LOCATION = 'LOCATION';
  private TOKEN = 'token';
  private REMEMBER_ME = 'remember';
  private currentUserSubject: BehaviorSubject<LoginResponse>;
  public currentUser: Observable<LoginResponse>; 
  public locationSubject: BehaviorSubject<any>;
  public locationObs: Observable<any>;


  constructor(private apiClient: ApiClientService , private router : Router, private cookieService:CookieService) { 
    this.currentUserSubject = new BehaviorSubject<LoginResponse>(this._retrieveLoggedInCustomerFromStorage());
    this.currentUser = this.currentUserSubject.asObservable();
    this.locationSubject = new BehaviorSubject<any>(this._retrieveLocation());
    this.locationObs = this.locationSubject.asObservable();
  }

  login(login: Login): Observable<LoginResponse> {
    localStorage.setItem(this.REMEMBER_ME, String(login.remember == undefined ? false : login.remember));
    return this.apiClient.postRequest<LoginResponse>(`auth/login/`, login, null, true);
  }

  registration(registeration : RegistrationModel) : Observable<any> {
    return this.apiClient.postRequest<any>(`auth/registration/`, registeration, null, true);
  }


  private _logout(): Observable<any> {
    return this.apiClient.getRequest<any>(`auth/logout/`, null, true);
  }


  refreshTokenOrLogout() : void {
     const  keepMeLogin = Boolean(localStorage.getItem(this.REMEMBER_ME));
     if (keepMeLogin) {
       this.refershToken(JSON.parse(localStorage.getItem(this.LOGGEN_IN_CUSTOMER)));
     } else {
       this.logout();
     }

  }

  logout() : void {
    this._logout().subscribe({
      next: d => {
        this._removeLoggedInCustomerFromStorage();
        this.currentUserSubject.next(null);
        this.router.navigate(['/']);    
      }
    })
    
  }

  refershToken(user: LoginResponse) {
    user.token = localStorage.getItem(this.TOKEN);
    return this.apiClient.postRequest<LoginResponse>(`token/refresh/`, user, null, false).subscribe( {
      next: r => localStorage.setItem(this.TOKEN, r.token),
      error: r=> this.logout()
    });

  }

  changePassword(changePassword : ChangePassword) : Observable<any> {
    return this.apiClient.postRequest<any>(`auth/password/change/`, changePassword, null, false);
  }

  resetPassword(resetPassword: ResetPassword) : Observable<any> {
    return this.apiClient.postRequest<any>(`auth/password/reset/`, resetPassword, null, false);
  }

  resetPasswordConfirmation(resetPasswordConfirm : ResetPasswordcConfirm) : Observable<any> {
    return this.apiClient.postRequest<any>(`auth/password/reset/confirm/`, resetPasswordConfirm, null, false);
  }

  verifyCustomer(): void{
      const customerData = this.curretUser;
      customerData.user.customer.is_verified = true;
      this.addLoggedInCustomerToStorage(customerData);
      this.currentUserSubject.next(customerData);

  }

  addLoggedInCustomerToStorage(loginResponse: LoginResponse) : void {
    this.currentUserSubject.next(loginResponse);
    this.currentUser = this.currentUserSubject.asObservable();
    localStorage.setItem(this.LOGGEN_IN_CUSTOMER, JSON.stringify(loginResponse));
    localStorage.setItem(this.TOKEN, loginResponse.token);
  }

  private _removeLoggedInCustomerFromStorage () : void {
    localStorage.removeItem(this.LOGGEN_IN_CUSTOMER);
    localStorage.removeItem(this.TOKEN);
    localStorage.clear();
  }

  private _retrieveLoggedInCustomerFromStorage () : LoginResponse {
    return JSON.parse(localStorage.getItem(this.LOGGEN_IN_CUSTOMER) || '{}');
  }

  public get curretUser () {
    return this.currentUserSubject.value;
  }

  private _retrieveLocation() : any {
    return JSON.parse(localStorage.getItem(this.CUSTOMER_LOCATION) == null  ? JSON.stringify({'name': 'Global', 'flag': ''}) : localStorage.getItem(this.CUSTOMER_LOCATION));
  }

  updateLocation(location: any): void {
    return localStorage.setItem(this.CUSTOMER_LOCATION, JSON.stringify(location));
  }

  isUserLoggedIn(): boolean {
    return Object.keys(this._retrieveLoggedInCustomerFromStorage()).length > 0;
  }

}
