

<footer class="footer section-gap pb-0">
  <div class="container">
      <div class="row">
          <div class="col-lg-3 mb-4">
              <!-- first col -->
              <div class="logo-col px-3" *ngIf="contactInfo$ | async as contactInfo">
                  <img src="../../../assets/images/home/cashiback-logo.png" alt="Footer Logo" srcset="" class="mb-3">
                  <p class="category-subtitle mb-4" i18n>{{contactInfo.address}}</p>
                  <div class="contact-footer">
                      <p class="mb-2"><a href="email:{{contactInfo.email}}" class="text-white"><i class="fal fa-envelope-open me-2"></i>
                        {{contactInfo.email}}</a></p>
                      <p class="mb-2"><a href="tel:{{contactInfo.telephone}}" class="text-white"><i class="fal fa-phone me-2"></i>
                        {{contactInfo.telephone}}</a></p>
                  </div>
              </div>
          </div>
          <div class="col-lg-3 mb-4">
              <!-- second col -->
              <div class=" quick-links px-3">
                  <h2 class="text-white category-title mb-3" i18n>Quick Links</h2>
                  <ul class="list-unstyled ">
                    <a href="#" routerLink="/about-us" routerLinkActive="true">
                      <li i18n>About US</li>
                    </a>
                    <a href="#" routerLink="/privacy" routerLinkActive="true">
                      <li i18n>Privacy Policy</li>
                    </a>
                    <a href="#" routerLink="/terms-and-condition" routerLinkActive="true">
                      <li i18n>Terms & Conditions</li>
                    </a>
                    <a href="#">
                      <li i18n>Site map</li>
                    </a>
                    <a href="#" routerLink="/contact-us" routerLinkActive="true">
                      <li i18n>Contact us</li>
                    </a>
                    <a href="#" routerLink="/help" routerLinkActive="true">
                      <li i18n>Help</li>
                    </a>
                  </ul>
              </div>
          </div>
          <div class="col-lg-3 mb-4">
              <!-- second col -->
              <div class=" quick-links px-3">
                  <h2 class="text-white category-title mb-3">Trending Stores</h2>
                  <ul class="list-unstyled " *ngFor="let s of stores">
                      <a href="#">
                          <li>{{s | translateName}}</li>
                      </a>
                      
                      
                  </ul>
              </div>
          </div>
          <div class="col-lg-3 mb-4">
              <!-- second col -->
              <div class=" quick-links px-3">
                  <h2 class="text-white category-title mb-3">All Categories</h2>
                  <ul class="list-unstyled " *ngFor="let g of categories">
                      <a href="#">
                          <li>{{g | translateName}} </li>
                      </a>
                     
                  </ul>
              </div>
          </div>
      </div>
      <div class="copyrights">
          <!-- social media icons -->
          <div class="social">
              <ul class="list-inline socialmedia">
                <li class="list-inline-item" *ngFor="let n of networks$| async as networks"><i class="fab fa-facebook-f"></i></li>
                  <li class="list-inline-item"><i class="fab fa-twitter"></i></li>
                  <li class="list-inline-item"><i class="fab fa-instagram"></i></li>
              </ul>
          </div>
          <h6 class="text-center text-light mb-0" i18n>Cashiback © 2023. All rights reserved.</h6>
      </div>
  </div>
</footer>
