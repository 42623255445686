import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';
import { StoreService } from 'src/app/core/service/store.service';

@Component({
  selector: 'app-top-stores',
  templateUrl: './top-stores.component.html',
  styleUrls: ['./top-stores.component.css']
})
export class TopStoresComponent implements OnInit {

  stores: Store [] = [];
  next: string;
  previuos: string;
  count: number
  constructor(private storeService : StoreService) { }

  ngOnInit() {
    this._retrieveTopStore();
  }

  private _retrieveTopStore(): void {
    this.storeService.retrireveTopStore().subscribe({
      next: data => {
        this.stores = data.results;
        this.next = data.next;
        this.previuos = data.previous;
        this.count = data.count;
      }
    })
  }

  onScroll() : void {
    if(this.next === null || this.next === undefined) return;    
    this.storeService.retrieveStoreByCategoryAndPaginationLink(this.next).subscribe((data) => {
      this.stores.concat(data.results);
      this.next = data.next;
      this.previuos = data.previous;
      this.count = data.count;
    })
  }

}
