import { Component, OnInit } from '@angular/core';
import { Categories } from 'src/app/core/models/categories.model';

@Component({
  selector: 'app-popular-content',
  templateUrl: './popular-content.component.html',
  styleUrls: ['./popular-content.component.css']
})
export class PopularContentComponent implements OnInit {

  category: Categories ;

  constructor() { }

  ngOnInit(): void {
  }

  populateCategoryStore(category): void{    
      this.category = category;
 
  }
}
