<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-body p-0">
      <div class="modal-register sign-up-form">
        <div class="card">

          <!-- Modal Header-->
          <div class="card-header pt-3 pb-0 px-5">
            <button type="button" aria-label="Close" data-dismiss="modal" class="btn close-form btn-close"></button>
            <div class="main-icon">
              <i class="far fa-lock-alt" aria-hidden="true"></i>
            </div>
            <h2 i18n>Forgot Password?</h2>
            <p i18n> If you didn't request a password reset, No further action is required.</p>
          </div>
          <!-- Modal Body-->
          <form #resetPasswordForm="ngForm" (ngSubmit)="resetPasswordForm.form.valid && doResetPassword()" novalidate>
            <div class="modal-card-body">

              <div class="rest-message" *ngIf="successMessage">
                <i class="fas fa-check-circle" aria-hidden="true"></i> {{successMessage}}
              </div>

              <div class="error-message" *ngIf="errorMessage">
                <i class="fas fa-check-circle" aria-hidden="true"></i> {{errorMessage}}
              </div>


              <!-- <label>Email</label> -->
              <input type="email" i18n-placeholder placeholder="Email address" name="email" required=""
                [(ngModel)]="passwordReset.email" #email="ngModel" required autocomplete="off"
                [ngClass]="(resetPasswordForm.submitted && email.invalid) ? 'form-control invalid' : ''" />
              <p *ngIf="resetPasswordForm.submitted && email.invalid && email.errors.required" class="invalid" i18n>
                Email is required
              </p>
            </div>
            <!-- Modal footer for signup bottom-->
            <div class="model-footer">
              <button type="submit" class="main-btn" style="width: auto !important;" i18n>Send Reset Instructions</button><br>
              Not a member? <a data-dismiss="modal" data-toggle="modal" data-target="#newRegisterModal" style="color: #f05864" i18n>Sign up
                now</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
