import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/service/common.service';
import { TermsAndCondition } from 'src/app/core/models/terms-and-condition.model';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  terms : TermsAndCondition;
  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this._retrieveTAC();
  }

  private _retrieveTAC() : void {
    this.commonService.retrieveTAC().subscribe(data => this.terms = data);
  }

}
