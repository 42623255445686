import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { RegistrationModel } from 'src/app/core/models/registration.model';
import { CountriesService } from 'src/app/core/service/countries.service';
import { Country } from 'src/app/core/models/countries.model';
import { CommonService } from 'src/app/core/service/common.service';
import { TermsAndCondition } from 'src/app/core/models/terms-and-condition.model';
import { environment } from 'src/environments/environment';
import { Rules } from 'src/app/core/models/rules.model';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/core/service/profile.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  @ViewChild('closeModel') closeModel:ElementRef;
  errors: string[] = [];
  registration : RegistrationModel = {};
  countries : Country [];
  hideTerms = true;
  hideReg = false;
  terms : TermsAndCondition = {};
  cdn = environment.cdn;
  cookieDomain = environment.cookieDomain;
  cashbackRule : Rules;
  first_country: Country;
  termConfirm!: boolean;
  referralCode!: string;
  constructor(private authService:AuthService, private countryService: CountriesService, private commonService: CommonService,
        private activatedRoute : ActivatedRoute, private toastService:ToastrService, private referralService: ProfileService, private cookieService: CookieService) { 

          this.activatedRoute.queryParamMap.subscribe(p => {
            this.referralCode = p.get('referral_code');
            
            cookieService.set('pinax-referral', cookieService.get('sessionid')+':' +this.referralCode, null, null, this.cookieDomain);
          })
        }

  ngOnInit() {
    this._retrieveCountries();
    this._retrieveTAC();
    this._retrieveCashback();
  }

  signup() {

      this.authService.registration(this.registration).subscribe({
        next: data => {
          this.toastService.success("Your registration completed successfully ..");
          this.closeModel.nativeElement.click();
          
        },
        error: data => {
            data.details.forEach(element => {
              this.errors.push(element);
            });
        },
      });
  }


  private _retrieveCountries () : void {

    this.countryService.retrieveCountries()
          .subscribe(data => {
            this.countries=data;
            this.registration.country_code = String(this.countries[0].pk);

          });
  }

  private _retrieveTAC () : void {
    this.commonService.retrieveTAC().subscribe(data => this.terms = data);
  }

  openTerms() : void{
    this.hideTerms = false;
    this.hideReg = true;
  }

  closeTerms() : void {
    this.hideTerms = true;
    this.hideReg = false;
  }

  openLoginDialog() {
    // const dialogRef = this.dialog.open(LoginComponent, {panelClass: 'myapp-no-padding-dialog'});
    // this.dialogRef.close();
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }

  close(): void {
    // this.dialogRef.close();
}

  private _retrieveCashback(): void {
    this.commonService.retrieveRules().subscribe({
      next: data => this.cashbackRule = data.find(x => x.type ='RewardBalance')
    })
  }
}
