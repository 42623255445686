import { Component, Input, OnInit } from '@angular/core';
import { CustomerBank } from 'src/app/core/models/customer.bank.model';
import { LoginResponse } from 'src/app/core/models/login-response.model';
import { Rules } from 'src/app/core/models/rules.model';
import { WithdrawalRequest } from 'src/app/core/models/withdrawal-req.model';
import { AuthService } from 'src/app/core/service/auth.service';
import { WithdrawalService } from 'src/app/core/service/withdrawal.service';
import { MobileVerificationComponent } from 'src/app/module/auth/mobile-verification/mobile-verification.component';
import { AddOnlineBankComponent } from '../add-online-bank/add-online-bank.component';

@Component({
  selector: 'app-online-bank-withdrawal',
  templateUrl: './online-bank-withdrawal.component.html',
  styleUrls: ['./online-bank-withdrawal.component.css']
})
export class OnlineBankWithdrawalComponent implements OnInit {

  @Input() banks : CustomerBank[] = [];
  @Input() min : Rules;
  @Input() max : Rules;
  loggedInUser: LoginResponse ;
  withdrawal : WithdrawalRequest = {};
  successMessage: string;
  errorMessage: string;


  constructor(private authService: AuthService,  private withdrawalService: WithdrawalService) {
    this.authService.currentUser.subscribe(x => this.loggedInUser=x);

   }

  ngOnInit(): void {
    console.log(this.banks);
    
  }

  openDialog() {
   
  }

  do() :void {
    this.withdrawalService.createWithdrawal(this.withdrawal).subscribe({
      next:data => this.successMessage = 'Withdrawal request completed',
      error: data => this.errorMessage = data.message[0],
      complete: () => console.log('completed')
    });
  }
  
 


}
