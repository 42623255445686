<div class="modal fade online-transfer-form" id="addEWalletForm" tabindex="-1" aria-labelledby="addEWalletFormLabel"
    aria-hidden="true">
    <form #f="ngForm" class="add-bank-account mb-0" (ngSubmit)="f.form.valid && addBank()">

        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title-upper">
                        <div class="title-icon">
                            <i class="fal fa-credit-card-front"></i>
                        </div>
                        <h5 class="modal-title" id="addEWalletFormLabel">Add an e-Wallet Account</h5>
                    </div>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <i class="fal fa-times" aria-hidden="true"></i>
                    </button>
                </div>
                <div class="modal-body">
                        <div class="form-row">
                            <div class="offset-md-2 col-md-8">
                                <div class="form-group">
                                    <label class="form-label">E-Wallet</label>
                                    <select class="form-control" [(ngModel)]="customerBank.bank" name="bank"
                                        #bank="ngModel">
                                        <option *ngFor="let bank of banks" [value]="bank.pk">{{bank.name_en}} </option>
                                    </select>
                                    <p *ngIf="f.submitted && bank.invalid && bank.errors.required"
                                        class="incorrect-code">
                                        Bank is required
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label class="form-label">Paypal Account Email </label>
                                    <input type="email" class="form-control" placeholder="abeerhasan@gmail.com"
                                        #accountNumber="ngModel" name="accountNumber"
                                        [(ngModel)]="customerBank.account_number" required [autocomplete]="false">
                                    <p *ngIf="f.submitted && accountNumber.invalid && accountNumber.errors.required"
                                        class="incorrect-code">
                                        Email is required
                                    </p>


                                </div>
                            </div>
                        </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary main-btn">Save Card</button>
                </div>
            </div>
        </div>
    </form>
</div>