import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfilesRoutingModule } from './profiles-routing.module';
import { ProfilesComponent } from './profiles.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CashbackOverviewComponent } from './cashback-overview/cashback-overview.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FavStoreComponent } from './fav-store/fav-store.component';
import { WithdrawalComponent } from './withdrawal/withdrawal.component';
import { CashbackActivityComponent } from './cashback-overview/cashback-activity/cashback-activity.component';
import { WithdrawalHistoryComponent } from './cashback-overview/withdrawal-history/withdrawal-history.component';
import { ClickActivityComponent } from './cashback-overview/click-activity/click-activity.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BankWithdrawalComponent } from './withdrawal/bank-withdrawal/bank-withdrawal.component';
import { OnlineBankWithdrawalComponent } from './withdrawal/online-bank-withdrawal/online-bank-withdrawal.component';
import { BankCardComponent } from './withdrawal/bank-card/bank-card.component';
import { AddOnlineBankComponent } from './withdrawal/add-online-bank/add-online-bank.component';
import { AddLocalBankComponent } from './withdrawal/add-local-bank/add-local-bank.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import {TableModule} from 'primeng/table';
import {BadgeModule} from 'primeng/badge';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [ProfilesComponent, AccountInfoComponent, CashbackOverviewComponent, ChangePasswordComponent, FavStoreComponent, WithdrawalComponent, CashbackActivityComponent, WithdrawalHistoryComponent, ClickActivityComponent, BankWithdrawalComponent, OnlineBankWithdrawalComponent, BankCardComponent, AddOnlineBankComponent, AddLocalBankComponent],
  imports: [
    CommonModule,
    ProfilesRoutingModule,
    InfiniteScrollModule,
    FormsModule,
    SharedModuleModule,
    ReactiveFormsModule,
    TableModule,
    BadgeModule,
    TranslateModule
  ]
})
export class ProfilesModule { }
