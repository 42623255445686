import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { LoginResponse } from 'src/app/core/models/login-response.model';
import { Router } from '@angular/router';
import { CountriesService } from 'src/app/core/service/countries.service';
import { Country } from 'src/app/core/models/countries.model';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-component',
  templateUrl: './header-component.component.html',
  styleUrls: ['./header-component.component.css']
})
export class HeaderComponentComponent implements OnInit {

  loggedInUser : LoginResponse;
  searchQuery : string;
  countries : Country [];
  cdn = environment.cdn;
  location: any;
  localeId : any;
  display: boolean;
  constructor(private authService: AuthService, private router:Router,  private countryService: CountriesService, private cookieService: CookieService, private translate: TranslateService) { 
    this.authService.currentUser.subscribe(x => this.loggedInUser=x);
    this.authService.locationObs.subscribe(x => this.location = x);
    this.localeId = this.translate.getDefaultLang();
  }

  ngOnInit() {
    this._checkIfReferralExist();
  }


  openLoginDialog() {
    
  }
 

  openRegistrationDialog() {
   
  }

  openVerificationDialog() {
  }
 
  logout() : void {
    this.authService.logout();
  }

  searchResult (): void {
    this.router.navigate(['/search', 'store'], {queryParams: {q: this.searchQuery}});
  }

  private _retrieveCountries () : void {
    this.countryService.retrieveCountries()
          .subscribe(data => this.countries=data);
  }

 
  changeLocation(country: Country) : void {   
      this.countryService.updateLocation({'name': country.name, 'flag': country.logo});
      this.countryService.changeLocation(country).subscribe(x => window.location.reload());
  }

  changeLang(target : string): void {
    this.translate.use(target);
    this.localeId = target;
  }

  private _checkIfReferralExist():void {
    if (this.cookieService.check('reg')) {
      this.openRegistrationDialog();
    }
  }

}