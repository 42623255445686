<div id= "redirect-to-link" class="container-fluid" *ngIf="store">
    <div class="top-redirect"></div>
    <div class="bottom-redirect">
        <div class="content-box">
            <img [src]="logo"/>
            <h4>{{store.name_en}}</h4>
            <h6 i18n>Awesome, {{loggedInUser.user.first_name}} {{loggedInUser.user.last_name}}! You’re on your way to</h6>
            <p class="red" i18n>Up to {{store.top_rate_v}}   Cash Back</p>
            <p> Cashback will be added to your account within {{store.claim_days}} DAYS!</p>
        </div>
        <p i18n>Taking too long to load? <a href="{{store.url}}">Get there now</a> </p>
    </div>
</div>