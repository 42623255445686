
<section class="products-cards section-gap">
    <div class="container">
        <div class="row">
            <div class="col-lg-9 text-center text-lg-start mb-3 mb-lg-0">
                <h2 i18n class="mb-3">Top Cash Book Stores</h2>
                <p class="text-secondary h6" i18n>Shop and get Cash Back at Top Cash Book stores </p>
            </div>
            <div class="col-lg-3 text-center text-lg-end">
                <a href="#" routerLink="stores/top" class="main-btn float-lg-end" i18n>
                    Show All
                    <i class="fal fa-arrow-right ms-1" aria-hidden="true"></i>
                  </a>
               
            </div>
            
        </div>

        <!-- first cards -->
        <div class="row mt-5">
            <!-- <app-promotion-card *ngFor="let store of stores" [store]="promotion"  class='col-lg-3 col-md-4 col-sm-6 mb-4 col-8 m-auto'></app-promotion-card> -->
            <app-top-store-card *ngFor="let store of stores" [store]="store"  class='col-lg-3 col-md-4 col-sm-6 mb-4 col-8 m-auto'></app-top-store-card>
        </div>
    </div>
</section>