<section class="first-referEarn">
  <div class="container">
    <div class="ref-earn-title">
      <h1 class="text-center">Refer &amp; Earn {{referral.rule.value}} SAR</h1>
      <p class="text-center container " i18n>Have a friend who loves to shop? Earn Raise Cash
        for every person you refer and help them save money,
        too. We’ll give you and your
        friend $5 just for spreading the good word!</p>
    </div>
  </div>
</section>

<section class="refer-steps section-gap" *ngIf="referral">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4  col-sm-12">
        <div class="main-icon text-center">
          <i class="fal fa-share-alt"></i>
        </div>
        <div class="step-details mt-5">
          <h2 class="text-center mb-3" i18n>Share your link</h2>
          <p class="text-center" i18n>with your friends via email, Facebook, Twitter and more!</p>
        </div>
      </div>

      <div class="col arrow-icon-line22 light-arrow">
        <i class="fas fa-chevron-right"></i>
      </div>

      <div class="col-lg-3 col-md-4  col-sm-12">
        <div class="main-icon text-center">
          <i class="fal fa-user"></i>
        </div>
        <div class="step-details mt-5">
          <h2 class="text-center mb-3" i18n>Friend Signs Up</h2>
          <p class="text-center">and gets {{referral.rule.value}} SAR Cashback bonus.</p>
        </div>
      </div>

      <div class="col arrow-icon-line22 light-arrow">
        <i class="fas fa-chevron-right"></i>
      </div>

      <div class="col-lg-3 col-md-4  col-sm-12">
        <div class="main-icon text-center">
          <i class="fal fa-usd-circle"></i>
        </div>
        <div class="step-details mt-5">
          <h2 class="text-center mb-3">You earn {{referral.rule.value}} SAR</h2>
          <p class="text-center" i18n>when your friend meets a minimum spend of $25.00</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="referral-link-wrapper" *ngIf="referral">
  <div class="container">
    <div class="referral-link section-gap">
      <div class="referral-details">
        <h2 class="text-center" i18n>
          Share your referral link
        </h2>
        <div class="copy-link mb-5">
          <input type="button" name="referralLink" [(ngModel)]="referalCode" #referralLink="ngModel">
          <button class="main-btn" [cdkCopyToClipboard]="referalCode" i18n><i class="fal fa-clipboard me-3"></i> Copy
            code </button>
        </div>
        <share-buttons class="share-options" [theme]="'modern-dark'"
          [include]="['facebook','twitter','linkedin','pinterest','reddit','tumblr','mix','viber','vk','telegram','messenger','whatsapp','xing','line','sms','email','print','copy']"
          [show]="5" [showText]="true" [url]="referalCode" [autoSetMeta]="false"></share-buttons>


      </div>
    </div>
  </div>
</section>


<section class="activity section-gap" *ngIf="referral">
  <div class="container">
    <h2 class="text-center text-md-start" i18n>Your Referral Activity</h2>
    <div class="row mt-5">
      <div class="col-10 col-md-4 mb-3 m-auto">
        <div class="activ-box">
          <div class="box-upper d-flex align-items-center justify-content-between border-bottom pb-3">
            <div class="left-side">
              <h2 class="mb-3 text-white" i18n>Invited</h2>
              <p class="mb-0" i18n>friends <span class="number">{{referral.total}}</span> </p>
            </div>
            <div class="main-icon m-0"><i class="fal fa-user-plus"></i></div>
          </div>
          <div class="dollar-bottom mt-3 fs-5">{{total | currency}}</div>
        </div>
      </div>
      <div class="col-10 col-md-4 mb-3 m-auto">
        <div class="activ-box">
          <div class="box-upper d-flex align-items-center justify-content-between border-bottom pb-3">
            <div class="left-side">
              <h2 class="mb-3 text-white" i18n> Signed Up</h2>
              <p class="mb-0" i18n>friends <span class="number">{{referral.success}}</span> </p>
            </div>
            <div class="main-icon m-0"><i class="fal fa-user"></i></div>
          </div>
          <div class="dollar-bottom mt-3 fs-5">{{success | currency}}</div>
        </div>
      </div>
      <div class="col-10 col-md-4 mb-3 m-auto">
        <div class="activ-box">
          <div class="box-upper d-flex align-items-center justify-content-between border-bottom pb-3">
            <div class="left-side">
              <h2 class="mb-3 text-white" i18n>Qualified</h2>
              <p class="mb-0" i18n>friends <span class="number">{{referral.pending}}</span> </p>
            </div>
            <div class="main-icon m-0"><i class="fal fa-star"></i></div>
          </div>
          <div class="dollar-bottom mt-3 fs-5">{{pending | currency}}</div>
        </div>
      </div>

    </div>
  </div>
</section>


<section class="refer-friend section-gap">
  <div class="container">
    <h2 class="refer-friend-title mb-5 text-center text-md-start" i18n>How Refer A Friend Works</h2>
    <div class="row">
      <div class="col-sm-6 col-xl-3 mb-3 mb-xl-0 position-relative text-center">
        <button class="btn-danger" i18n>Share your link</button>
        <div class="text-content text-center border-0">
          <div class="icon"> <i class="far fa-laptop"></i></div>
          <h2>Pending Sign up</h2>
          <p>Indicates that your friend has not signed up with cashiback via your link</p>
        </div>
      </div>

      <div class="col-sm-6 col-xl-3 mb-3 mb-xl-0 position-relative text-center">
        <button class="btn-danger" i18n>Friend Signs up</button>

        <div class="text-content text-center">
          <div class="icon"> <i class="far fa-shopping-cart"></i></div>
          <h2>Pending {{referral.rule.value | currency}} 
            Purchase</h2>
          <p>Indicates that your friend has not made purchase(s)
            totaling {{referral.rule.value | currency}} </p>
        </div>
      </div>

      <div class="col-sm-6 col-xl-3 mb-3 mb-xl-0 position-relative text-center">
        <button class="btn-danger" i18n>Friend Purchased</button>


        <div class="text-content text-center">
          <div class="icon"> <i class="far fa-clock"></i></div>
          <h2 i18n>Pending Redeemable
            Cshback</h2>
          <p i18n>Indicates that your friend is waiting for his/her Cashback to turn redeemable</p>
        </div>
      </div>

      <div class="col-sm-6 col-xl-3 mb-3 mb-xl-0 position-relative text-center">
        <button class="btn-danger" i18n>Cashback Redeemable</button>
        <div class="text-content text-center">
          <div class="icon"> <i class="fal fa-sack-dollar"></i></div>
          <h2 i18n>Successfully Referred</h2>
          <p i18n>Indicates that you’ve successfully referred your friend</p>
        </div>
      </div>

    </div>
  </div>
</section>


<section class="condition section-gap">
  <div class="container">
    <div class="row ">
      <div class="col">
        <h1 class="text-start h1 mb-5" i18n>Refer a friend Term &amp; Condition</h1>
        <p class="first-content mb-5" i18n>
          Cashibak rewards you with a $5 bonus for every qualified new member referred. A “qualified referral” is a new
          member of ShopBack that signs up via (i) a referrer’s unique referral link or on ShopBack, (ii) the referral
          methods on this page, and proceeds to make purchase(s) totalling $25 (final order value) or (iii) Referrals’
          first purchase must be a legitimate order non-stackable with other New Customer promotions (i.e. Everything at
          $1) and any cancellations, refunds or exchanges will be disqualified. $5 will be credited into your ShopBack
          account only when your referrals hit the minimum spend of $25 and when their Cashback turns redeemable. Note
          that Uber fares won’t be able to qualify towards the $25 <br><br>

          If referred via email, your friend has to input the referred email address when signing up on ShopBack. Any
          orders that do not earn cashback will not be counted towards the minimum spend requirement. <br> <br>

          The following is a non-exhaustive list of activities that are not permitted and that will disqualify you from
          earning referral bonuses: (i) self-referral, (ii) posting your referral link on any ShopBack merchant’s
          Facebook or forum page, (iii) keyword bidding for the purpose of generating traffic to pages containing your
          referral link, and (iv) any similar activity determined by ShopBack in its sole discretion to be inconsistent
          with the purpose of ShopBack’s Refer-a-Friend Programme. ShopBack reserves the right to alter the terms and
          conditions of this promotion. Any infringement of the above terms and conditions would result in the loss of
          all bonuses. Repeat offences would result in the closure of your ShopBack account. <br> <br>



          Please note that the following merchants are excluded from the qualifying spend: <br>

          Grab, Apple Music, Chateau de Sable, CIMB, Comfort Taxi, Dreamstime.com, Eatigo, Expansys, Fragrance Shop,
          Guitar Center, Helpling, HotelClub.com, HungryGoWhere, iShopChangi, Kmart, MyRepublic fibre broadband,
          Quandoo, Roomorama, Ryde Taxi, Sears, Singtel Music, Stamps.com, Tangs, Uber, Uber Driver, UOB, Wogi, Zipcar,
          all Credit Card merchants. <br> <br>



          Please note this is not an exhaustive list. ShopBack reserves the right in its sole and absolute discretion to
          amend and vary the terms and conditions as set out above, as well as to disqualify any User at any point of
          time.



          Personal Data

          In this Personal Data Protection Policy, “Personal Data” refers to any data, whether true or not, about an
          individual who can be identified from that data, or from that data and other information to which we have or
          are likely to have access, including data in our records as may be updated from time to time.


          Collection of Personal Data

          The Personal Data that ShopBack may collect from you will depend on the products, services, and promotions
          that you use or subscribe to. ShopBack collects this data to enable our services and our platforms to function
          properly and to help us better understand how they work on different devices and browsers. Typically, ShopBack
          will collect Personal Data relating to you when you:


        </p>

        <div class="faq-refer-wrapper mb-5">
          <h3 class="second-title mb-3" i18n>12 - 18 Jun 2020 Bonus Promotion Terms and Conditions</h3>
          <p class="second-content" i18n>


            From 12 - 18 Jun, earn the usual $5 + extra $5 bonus per successful referral. Receive extra $2 when your
            friend links their first credit/debit card on ShopBack GO and another extra $2 when your friend makes their
            first ShopBack GO purchase. For example, if you refer 2 friends, you will earn 2 x $5 (usual) + 2 x $5
            (bonus)
            = $20 in total. You will receive the bonus (see above for definition of a “qualified referral”) by 31 Aug
            2020. The usual Cashback will be tracked in your account automatically. Users qualify for this bonus
            promotion
            per email account on ShopBack. If your successfully referred friend links their first debit/credit card with
            ShopBack GO, you will receive additional $2. For more details, please refer to the terms and conditions
            here..
          </p>
        </div>
        <div class="faq-refer-wrapper mb-5">
          <h3 class="second-title mb-3" i18n>13 - 21 Apr 2020 Bonus Promotion Terms and Conditions</h3>
          <p class="first-content" i18n>
            From 13 - 21 Apr, earn the usual $5 + extra $3 bonus per successful referral.
            Receive extra $2 when your friend links their first credit/debit card on ShopBack GO and another
            extra $2 when your friend makes their first ShopBack GO purchase. For example, if you refer 2 friends,
            you will earn 2 x $5 (usual) + 2 x $3 (bonus) = $16 in total.
            You will receive the bonus (see above for definition of a “qualified referral”) by 30 Jun 2020.
            The usual Cashback will be tracked in your account automatically.
            Users qualify for this bonus promotion per email account on ShopBack.
            If your successfully referred friend links their first debit/credit card with ShopBack GO,
            you will receive additional $2. For more details, please refer to the terms and conditions
          </p>
        </div>
        <div class="faq-refer-wrapper mb-5">
          <h3 class="second-title mb-3" i18n>14- 16 Feb 2020 Bonus Promotion Terms and Conditions</h3>
          <p class="first-content" i18n>From 12 - 18 Jun, earn the usual $5 + extra $5 bonus per
            successful referral. Receive extra $2 when your friend links their first credit/debit card on
            ShopBack GO and another extra $2 when your friend makes their first ShopBack GO purchase.
            For example, if you refer 2 friends,
            you will earn 2 x $5 (usual) + 2 x $5 (bonus) = $20 in total. You will receive the bonus
            (see above for definition of a “qualified referral”) by 31 Aug 2020. The usual Cashback will be tracked in
            your account automatically. Users qualify for this bonus promotion per email account on ShopBack.
            If your successfully referred friend links their first debit/credit card with ShopBack GO,
            you will receive additional $2. For more details, please refer to the terms and conditions here..

          </p>
        </div>
        <div class="faq-refer-wrapper">
          <h3 class="second-title mb-3 mb-2" i18n> 28 Nov - 2 Dec 2019 Bonus Promotion Terms and Conditions
          </h3>
          <p class="first-content" i18n>From 13 - 21 Apr, earn the usual $5 + extra $3 bonus per successful referral.
            Receive extra $2 when your friend links their first credit/debit card on ShopBack GO and another extra
            $2 when your friend makes their first ShopBack GO purchase. For example, if you refer 2
            friends, you will earn 2 x $5 (usual) + 2 x $3 (bonus) = $16 in total. You will receive the bonus
            (see above for definition of a “qualified referral”) by 30 Jun 2020. The usual Cashback will be tracked
            in your account automatically. Users qualify for this bonus promotion per email account on ShopBack.
            If your successfully referred friend links their first debit/credit card with ShopBack GO,
            you will receive additional $2. For more details, please refer to the terms and conditions</p>
        </div>


      </div>
    </div>
  </div>

</section>
<app-mobile-app-component></app-mobile-app-component>
