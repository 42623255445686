import { Component, Input, OnInit } from '@angular/core';
import { Store } from 'src/app/core/models/store.model';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {
  @Input() store: Store;
  constructor() { }

  ngOnInit(): void {
  }

}
