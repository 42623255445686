<section class="about section-gap">
  <div class="container">
    <div class="title-wrapper mb-5">
      <h3 class="title" i18n>About CashiBack</h3>
      <p class="sub_title">lorem ipsum dolor sit amet consectetur</p>
    </div>

    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
      nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
      anim id est laborum.</p>


    <div class="banner-wrapper">
      <div class="banner-info">
        <div class="row">
          <div
            class="col-lg-5 offset-lg-1 order-last order-lg-first text-center text-lg-start mt-5 mt-lg-0 d-flex justify-content-center flex-column">
            <div class="about-content">
              <span>CashiBack</span>
              <h2>Get up to 40% Cash Back at over 2,500 stores</h2>
            </div>
          </div>
          <div class="col-lg-6 order-first order-lg-last text-center">
            <img src="../../../../assets/images/about-page/about-image.png" alt="About Image" class="p-5 p-md-0">
          </div>
        </div>
      </div>
    </div>


    <p>Aliquam faucibus purus in massa tempor nec. Feugiat scelerisque varius morbi enim nunc faucibus a
      pellentesque sit. Nibh venenatis cras sed felis eget velit aliquet. Sagittis orci a scelerisque purus semper
      eget. Consequat interdum varius sit amet mattis vulputate. Parturient montes nascetur ridiculus mus mauris.
      Nunc pulvinar sapien et ligula ullamcorper malesuada proin libero nunc. Lacinia at quis risus sed vulputate
      odio ut. Congue eu consequat ac felis donec. Hendrerit dolor magna eget est lorem ipsum dolor. Cras
      fermentum odio eu feugiat pretium nibh. Sodales ut eu sem integer vitae justo. At imperdiet dui accumsan sit
      amet. Ac tortor vitae purus faucibus.</p>

    <p class="pb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Euismod in pellentesque massa placerat duis ultricies lacus. Donec ultrices tincidunt arcu non
      sodales neque sodales ut etiam. Libero volutpat sed cras ornare arcu dui. Enim lobortis scelerisque
      fermentum dui faucibus in ornare quam. Et odio pellentesque diam volutpat commodo sed egestas egestas
      fringilla. Netus et malesuada fames ac turpis egestas maecenas pharetra convallis. Sapien et ligula
      ullamcorper malesuada proin libero nunc. Feugiat vivamus at augue eget arcu dictum varius. Lectus quam id
      leo in. Viverra vitae congue eu consequat. Et malesuada fames ac turpis egestas integer eget. Convallis
      aenean et tortor at risus viverra adipiscing at. Interdum consectetur libero id faucibus. Quam vulputate
      dignissim suspendisse in. Sit amet aliquam id diam maecenas.</p>

    <div class="row mt-5">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <ul>
          <li>Aliquam faucibus purus in massa tempor. Feugiat scelerisque</li>
          <li>Aliquam faucibus purus in massa tempor. Feugiat scelerisque</li>
        </ul>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <ul>
          <li>Aliquam faucibus purus in massa tempor. Feugiat scelerisque</li>
          <li>Aliquam faucibus purus in massa tempor. Feugiat scelerisque</li>
        </ul>
      </div>
    </div>

    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat.
      Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
      laborum.</p>

  </div>
</section>
<app-mobile-app-component></app-mobile-app-component>