import { Component, OnInit, ViewChild } from '@angular/core';
import { ResetPasswordcConfirm } from 'src/app/core/models/reset-password-confirm.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-complete-reset-password',
  templateUrl: './complete-reset-password.component.html',
  styleUrls: ['./complete-reset-password.component.css']
})
export class CompleteResetPasswordComponent implements OnInit {
  resetPasswordConfirm : ResetPasswordcConfirm = {};
  errorMessage: string;
  @ViewChild('resetPasswordConfirmForm') resetPasswordConfirmForm : NgForm;
  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    this.resetPasswordConfirm.token = this.route.snapshot.paramMap.get('token');
    this.resetPasswordConfirm.uid = this.route.snapshot.paramMap.get('uid');

  }

  doResetPasswordConfirm() : void {
      this.authService.resetPasswordConfirmation(this.resetPasswordConfirm).subscribe({
        next: data => this.router.navigate(['auth', 'password', 'reset', 'confirm', 'complete']),
        error: data => this.errorMessage = data.details[0]
      });
  }
}
