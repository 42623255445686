<div class="container mb-5 change-password">
    <div class="row container mb-3">
        <div class="col-lg-12 col-xs-12">
            <div class="check-img">
              <img src="../../../../assets/images/sign-in/Check.png">
            </div>
            <h2 i18n>Password Changed Successfully!</h2>
            <p i18n>You can now sign in with your new password.</p>
            <form>
              <button class="btn  btn-danger " i18n (click)="openLoginDialog()">Sign In</button>
            </form>
        </div>
    </div>
</div>