<div class="bg-gray-section">
    <!-- background image under the header section        -->
</div>

<section class="profile-body mb-5">
    <div class="container">
        <div class="row profile-row">
            <!-- start profile page side bar -->
            <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="profile-sidebar">
                    <!-- ### start profile image section ### -->
                    <div class="profile-image ">
                        <div class="user-status">
                            <img src="../../../assets/images/profile/user_status.png" alt="" class="profile-img">
                            <!-- <img src="./images/profile/ar-camera.png" alt="" srcset="" class="ar-camera"> -->
                            <!--<input id="input-8" type="file" accept="image/*" class="" data-allowed-file-extensions="[]" data-show-preview="false">-->
                            <h1 class="user-name">{{loggedInUser.user.first_name }} {{loggedInUser.user.last_name}}</h1>
                            <p class="user-date">User since {{loggedInUser.user.date_joined|date:'yyyy'}}</p>
                        </div>
                    </div>
                    <!-- ### start life time earning section ### -->
                    <div class="life-time-earn d-flex align-items-center justify-content-between">
                        <p class="life-time-p mb-0" i18n><i class="fal fa-usd-circle me-2"></i>{{'profile.life-time-earing' | translate}} </p>
                        <span class="life-time-money mb-0">{{loggedInUser.user.customer.available_balance | currency }}</span>
                    </div>

                    <!-- ## start balance section ## -->

                    <div class="balance-section">
                        <div class="available mb-3 d-flex align-items-center justify-content-between">
                            <p i18n> {{'profile.availabe-balance' | translate}} <i class="fal fa-info-circle"></i></p>
                            <span>{{loggedInUser.user.customer.available_balance | currency }}</span>
                        </div>

                        <div class="pending mb-3 d-flex align-items-center justify-content-between">
                            <p i18n> {{'profile.pending-balance' | translate}} <i class="fal fa-info-circle"></i></p>
                            <span>{{loggedInUser.user.customer.pending_balance | currency }}</span>
                        </div>

                        <div class="withdraw d-flex align-items-center justify-content-between">
                            <p i18n> {{'profile.withdrawn-balance' | translate}} <i class="fal fa-info-circle"></i></p>
                            <span>{{loggedInUser.user.customer.withdraw_balance | currency}}</span>
                        </div>

                    </div>

                    <!-- ## strat vertical menu items ##  -->
                    <!-- A grey horizontal navbar that becomes vertical on small screens -->
                    <div class="vertical-items pb-2 border-top">
                        <nav class="navbar ">
                            <!-- Links -->
                            <ul class="navbar-nav">
                                <li class="nav-item " [ngClass]="isActiveLink('cashback-overview') ? 'active' : ''">
                                    <a class="nav-link" href="#" routerLink="./cashback-overview" i18n> <i
                                            class="fal fa-poll-h"></i>
                                        {{'cashback-overview' | translate}}</a>
                                </li>
                                <li class="nav-item" [ngClass]="isActiveLink('account-info') ? 'active' : ''">
                                    <a class="nav-link" href="#" routerLink="./account-info" i18n> <i
                                            class="fal fa-user-circle"></i>
                                        {{'account-information' | translate}}</a>
                                </li>
                                <li class="nav-item" [ngClass]="isActiveLink('fav-store') ? 'active' : ''">
                                    <a class="nav-link" href="#" routerLink="./fav-store" i18n> <i
                                            class="fal fa-star"></i> {{'favorite-store' | translate}}</a>
                                </li>

                                <li class="nav-item" [ngClass]="isActiveLink('change-password') ? 'active' : ''">
                                    <a class="nav-link" href="#" routerLink="./change-password" i18n> <i
                                            class="fal fa-lock-alt"></i>
                                        {{'update-password' | translate}}
                                    </a>
                                </li>

                                <li class="nav-item" [ngClass]="isActiveLink('withdrawal') ? 'active' : ''">
                                    <a class="nav-link" href="#" routerLink="./withdrawal" i18n> <i
                                            class="fal fa-envelope-open-dollar"></i> {{'withdraw-money' | translate}}
                                    </a>
                                </li>

                                <li class="nav-item" [ngClass]="isActiveLink('referrals') ? 'active' : ''">
                                    <a class="nav-link" href="#" routerLink="../referrals" i18n> <i
                                            class="fal fa-user-plus"></i> {{'refer-and-earn' | translate }}</a>
                                </li>

                                <li class="nav-item" [ngClass]="isActiveLink('help') ? 'active' : ''">
                                    <a class="nav-link" href="#" routerLink="../help" i18n> <i
                                            class="fal fa-question-circle"></i> {{'help' | translate}}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!-- ### start vertical tabs with icons section ### -->
            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div class="main-tabs-col ps-0 ps-lg-5">
                    <router-outlet></router-outlet>

                </div>
            </div>
        </div>
    </div>
</section>
