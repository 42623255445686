import { Injectable } from '@angular/core';
import { ApiClientService } from '../http-client/api-client.service';
import { Observable } from 'rxjs';
import { Country } from '../models/countries.model';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  private location = 'LOCATION';

  constructor(private apiClient: ApiClientService) { }

  retrieveCountries () : Observable<Country[]> {
    return this.apiClient.getRequest<Country[]>(`countries`);
  }


  changeLocation (country: Country) : Observable<any> {  
    return this.apiClient.postRequest<any>(`locations`, country, null, true);
  }

  private _retrieveLocation() : any {
    return JSON.parse(localStorage.getItem(this.location) == null  ? JSON.stringify({'name': 'Global', 'flag': ''}) : localStorage.getItem(this.location));
  }

  updateLocation(location: any): void {
    return localStorage.setItem(this.location, JSON.stringify(location));
  }
}
