import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Sliders } from '../models/sliders.model';
import { ApiClientService } from '../http-client/api-client.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private apiClient: ApiClientService) { }

  retrieveSliders() : Observable<Sliders[]> {
    return this.apiClient.getRequest<Sliders[]>(`sliders`);
  }

  
}
