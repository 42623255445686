<div class="modal-dialog">
  <div class="modal-content">
    <form #loginForm="ngForm" (ngSubmit)="loginForm.form.valid && doLogin()" novalidate class="sign-up-form">
      <div class="card">
        <!-- Modal Header-->
        <div class="card-header pt-3 pb-0 px-5">
          <button type="button" class="btn close-form btn-close" aria-label="Close" data-dismiss="modal"
            #closeModel></button>
          <div class="main-icon">
            <i class="far fa-user-circle"></i>
          </div>
          <div class="header-content mb-4">
            <h2>{{'login.lbl-sign-in-now' | translate}}</h2>
            <p>{{'login.lbl-get-more' | translate}}</p>
          </div>
        </div>
        <!-- Modal Body-->
        <div class="modal-card-body px-5 py-4">
          <div class="row">

            <div class="col-12 mb-3">
              <label>{{'login.lbl-user-or-email' | translate}}</label>
              <input type="text" class="form-control" i18n-placeholder
                placeholder="{{'login.lbl-user-or-email' | translate}}" [(ngModel)]="loginRequest.username"
                #usernmame="ngModel" required name="username" autocomplete="off"
                [ngClass]="(loginForm.submitted && usernmame.invalid) ? 'form-control invalid' : ''" />
              <p *ngIf="loginForm.submitted && usernmame.invalid && usernmame.errors.required" class="invalid">
                {{'login.message-username-is-required' | translate}}
              </p>
            </div>
            <div class="col-12 mb-3 position-relative">
              <label>{{'login.lbl-password' | translate}}</label>
              <input type="password" class="form-control" i18n-placeholder
                placeholder="{{'login.lbl-password' | translate}}" [(ngModel)]="loginRequest.password"
                #password="ngModel" required name="password"
                [ngClass]="loginForm.submitted && password.invalid ? 'form-control invalid' : ''">
              <p *ngIf="loginForm.submitted && password.invalid && password.errors.required" class="invalid">
                {{'login.message-password-is-required' | translate}}
              </p>

              <a class="forget_password" data-dismiss="modal" data-toggle="modal" data-target="#resetPassword">
                {{'login.lbl-forget-passwor' | translate}}</a>
            </div>
          </div>

        </div>
        <!-- Modal footer for signup bottom-->
        <div class="model-footer px-5">
          <button type="submit" class="main-btn" >{{'sign-in' | translate}}</button>
          <br/> {{'login.lbl-not-a-member' | translate}}
          <a data-dismiss="modal" data-toggle="modal" data-target="#newRegisterModal" style="color: #f05864" >{{'lbl-sign-up-now' | translate}}</a>
        </div>
      </div>
    </form>
  </div>
</div>