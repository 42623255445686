<h1 class="main-header-title withdraw-title mb-0"><i class="fal fa-envelope-open-dollar"></i>
  Withdraw Money</h1>

<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <a class="nav-link active pt-0 px-0" id="bank-transfer-tab" data-toggle="tab" href="#bank-transfer" role="tab"
      aria-controls="bank-transfer" aria-selected="true"><i class="fal fa-credit-card-front"></i>Bank Transfer</a>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link pt-0 px-0" id="e-wallet-transfer-tab" data-toggle="tab" href="#e-wallet-transfer" role="tab"
      aria-controls="e-wallet-transfer" aria-selected="false"><i class="fal fa-wallet"></i>E-Wallet Transfer</a>
  </li>

</ul>
<div class="tab-content online-transfer" id="myTabContent">
  <div class="tab-pane fade show active" id="bank-transfer" role="tabpanel" aria-labelledby="bank-transfer-tab">
    <app-bank-withdrawal [banks]="localBanks" [min]="minimumWithdrawalRule" [max]="maxWithdrawalPerMonthRule">
    </app-bank-withdrawal>

  </div>

  <div class="tab-pane fade" id="e-wallet-transfer" role="tabpanel" aria-labelledby="e-wallet-transfer-tab">
    <app-online-bank-withdrawal [banks]="onlineBanks" [min]="minimumWithdrawalRule" [max]="maxWithdrawalPerMonthRule">
    </app-online-bank-withdrawal>

  </div>

</div>
