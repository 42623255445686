<div class="tabs-content-top-selling home-products section-gap pt-0" id="popular" *ngIf="category">
  <div class="container">
    <h4 class="text-center text-lg-start" i18n>{{category | translateName}} Content</h4>
    <div class="row mt-5 row-cols-2 row-cols-md-4 row-cols-lg-6">
      <app-populare-store-card [store]="store" *ngFor="let store of stores | slice:0:6 " class="col single-pro-wrapper">
      </app-populare-store-card>
    </div>
    <!-- second product section -->
    <div class="row products-border" *ngIf="stores.length > 6">
      <app-populare-store-card [store]="store" *ngFor="let store of stores | slice:6:12 "></app-populare-store-card>
    </div>
  </div>
</div>
